import React from "react"

import { ReactComponent as Instagram } from "../../assets/svg/instagram.svg" 
import { ReactComponent as Facebook} from "../../assets/svg/facebook.svg" 
import { ReactComponent as Telegram} from "../../assets/svg/telegram.svg" 
import { ReactComponent as Twitter} from "../../assets/svg/twitter.svg" 
import { ReactComponent as LinkedIn } from "../../assets/svg/linkedin.svg" 

function Icons(props) {
  return(
    <div className = { `media-icons-wrapper ${props.myClass?.container}`} style = {props.containerStyle}>
        {Object.keys(props.links).map((media,i) => {
          return(
             <Icon media = {media} link = {props.links[media]} style = {props.style} myClass = {props.myClass} key = {media}/>
          )
        }
        )}
    </div>
    )
}
export default Icons;

function Icon(props) {
  if(props.media === "facebook") {
  return(
    <a className = {` ${props.myClass.anchor}`} href = {props.link} target = "_blank" rel="noreferrer">
    <Facebook style = {{
      ...props.style
    }}  className = {` ${props.myClass.icon} `} />
    </a>
    )
}
if(props.media === "instagram") {
  return(
    <a className = {` ${props.myClass.anchor}`} href = {props.link}>
    <Instagram style = {{
      ...props.style
    }}  className = {` ${props.myClass.icon}`} />
    </a>
    )
}
if(props.media === "telegram") {
  return(
    <a className = {` ${props.myClass.anchor}`} href = {props.link}>
    <Telegram style = {{
      ...props.style
    }}  className = {` ${props.myClass.icon}`} />
    </a>
    )
}
if(props.media === "linkedin") {
  return(
    <a className = {` ${props.myClass.anchor}`} href = {props.link}>
    <LinkedIn style = {{
      ...props.style
    }}  className = {` ${props.myClass.icon}`} />
    </a>
    )
}
if(props.media === "twitter") {
  return(
    <a className = {` ${props.myClass.anchor}`} href = {props.link}>
    <Twitter style = {{
      ...props.style
    }}  className = {` ${props.myClass.icon}`} />
    </a>
    )
}
}