import React, { useState } from "react";
import { ReactComponent as MenuIcon } from "../../assets/svg/menu.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowdown.svg";
import { Link, useNavigate } from "react-router-dom";
import "./admin.css";
import { useProviderContext } from "./provider/adminProvider";

const menuData = [
  {
    title: "Events",
    links: [
      {
        title: "Add Events",
        link: "/admin/post/add-event",
      },
      {
        title: "See All Events",
        link: "/admin/all-events",
      },
    ],
  },
  {
    title: "Blogs",
    links: [
      {
        title: "Add Blogs",
        link: "/admin/post/add-blog",
      },
      {
        title: "All Blogs",
        link: "/admin/all-blogs",
      },
    ],
  },
  {
    title: "Album (Gallery)",
    links: [
      {
        title: "Add Album",
        link: "/admin/post/add-album",
      },
      {
        title: "All Albums",
        link: "/admin/all-albums",
      },
    ],
  },
  {
    title: "Feedback Messages",
    links: [
      {
        title: "All Message",
        link: "/admin/feedback-message",
      },
    ],
  },
  {
    title: "Daily Updates",
    links: [
      {
        title: "Send Daily Updates",
        link: "/admin/post/daily-update",
      },
    ],
  },
  {
    title: "Volunteers",
    links: [
      {
        title: "Registered Volunteer",
        link: "/admin/volunteer-list",
      },
    ],
  },
  {
    title: "Admins",
    links: [
      {
        title: "New admin",
        link: "/admin/post/add-admin",
      },
      {
        title: "All admins",
        link: "/admin/all-admins",
      },
    ],
  },
];

function AdminNavBar(props) {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const navigator = useNavigate();
  const { logoutAdmin } = useProviderContext();
  const handleLogout = async () => {
    logoutAdmin();
    navigator("/admin/abux-security/login");
  };
  return (
    <div className="admin-navbar-wrapper">
      <div className="admin-navbar">
        <div className="admin-navbar-menu-icon-wrapper">
          <MenuIcon
            className="admin-navbar-menu-icon"
            onClick={() => setIsSideBarOpen(true)}
          />
        </div>
        <div className="admin-navbar-identity">
          <div onClick={handleLogout} className="admin-navbar-logout">
            Logout
          </div>
          <div className="admin-navbar-avatar">
            <p className="avatar-name">
              {props.admin && props.admin.email.slice(0, 2).toUpperCase()}
            </p>
          </div>
        </div>
      </div>
      {isSideBarOpen && (
        <AdminSlideBar
          setIsSideBarOpen={setIsSideBarOpen}
          admin={props.admin}
        />
      )}
    </div>
  );
}

function AdminSlideBar(props) {
  const handleSideBarVanish = (e) => {
    if (
      e.target.id === "admin-fixed-bar" ||
      e.target.id === "admin-fixed-icon"
    ) {
      props.setIsSideBarOpen(false);
    }
  };
  return (
    <div
      className="admin-slide-bar-container"
      onClick={handleSideBarVanish}
      id="admin-fixed-bar"
    >
      <div className="admin-slide-bar-wrapper admin-slide-bar-wrapper-animate">
        <div className="admin-slide-bar">
          <div className="admin-slide-bar-topic">
            <h3 className="admin-slide-bar-title">Holistic Admin</h3>
            <p className="admin-slide-bar-user">{props.admin.email}</p>
          </div>
          {menuData.map((itm) => (
            <AdminSlideBarDropper
              key={itm.title}
              data={itm}
              setIsSideBarOpen={props.setIsSideBarOpen}
            />
          ))}
        </div>
        <div
          id="admin-fixed-icon"
          className="slide-bar-vanisher roboto"
          onClick={handleSideBarVanish}
        >
          <p id="admin-fixed-icon">X</p>
        </div>
      </div>
    </div>
  );
}

function AdminSlideBarDropper(props) {
  const [isDroped, setIsDroped] = useState(false);
  return (
    <div className="slide-bar-dropper-wrapper">
      <div
        className="slide-bar-dropper"
        onClick={() => setIsDroped((state) => !state)}
      >
        <div className="slide-bar-dropper-text">{props.data.title}</div>
        <ArrowDownIcon
          className={`arrow-down-icon ${
            isDroped ? "admin-drop-down-icon-animate" : ""
          }`}
        />
      </div>
      {isDroped && (
        <div className="slide-bar-droping">
          {props.data.links.map((link) => (
            <Link
              className="admin-slide-bar-link"
              key={link.title}
              to={link.link}
              onClick={() => props.setIsSideBarOpen(false)}
            >
              {link.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default AdminNavBar;
