import {Helmet} from "react-helmet-async";
function SEO({title,description,keywords}) {
  return (
    <Helmet>
   <meta
   name="viewport"
   content="width=device-width, initial-scale=1.0"/>
   <title>HOLISTIC - {title}</title>
  {description && <meta  
   name="description"
  content = { "Kolfe mulu wengel Holistic KFGC - " + description}
    />}
  <meta
  name="keywords" 
  content={ "Kolfe mulu wengel,kolfe full gospel, KFGC, Holistic , " + keywords} 
  />
  </Helmet>
    )
}
export default SEO;