import React from "react"
import {useNavigate} from "react-router-dom"
import { ReactComponent as Blood } from "../../assets/svg/blood.svg" 
import { ReactComponent as Donate } from "../../assets/svg/donate.svg" 
import { ReactComponent as Family} from "../../assets/svg/family.svg" 
import { ReactComponent as Child } from "../../assets/svg/children.svg" 
function Card1() {
const navigator = useNavigate()
  return(
    <div className = "what-we-do-dad">
    <div className = "what-we-do-wrapper">
       <div className = "what-we-do">
          <h2 className = "what-we-do-title">
             Things About <span className = "what-we-do-itself">
             What We Do
             </span>
          </h2>
          <p className = "what-we-do-description">
             Things Holistic Service team serve for society and accomplishments in our Journy
          </p>
          <button  onClick = {() => navigator("/aboutus")} className = "what-we-do-button">
          Discover more
          </button>
       </div>
       <div className = "what-we-do-card-container">
       <div className = "what-we-do-card-wrapper"> 
    <WhatWeDoCard 
    icon = {<Donate className = "what-we-do-card-icon"/>} 
    title = "Fundraising"
    text = "Preparing Events,Programs and online programs to fund raise in fund,material ..."/>
     <WhatWeDoCard 
     icon = {<Family className = "what-we-do-card-icon"/>}
     title = "Family Empowerment"
     text = "Empowering Family in financial, supporting in basic needs and counselling."
     />
      </div>
       <div className = "what-we-do-card-wrapper"> 
        <WhatWeDoCard 
       icon = {<Child className = "what-we-do-card-icon"/>} 
      title = "Child Care"
       text = "Visiting orphange and caring childrens to build Good and productive Generation"
       />
      <WhatWeDoCard
      icon = {<Blood className = "what-we-do-card-icon"/>}
     title = "Blood Donation"
      text = "Facilitating Blood donation event in 3 months to reduce blood shortage in our society."
      />
       </div>
      </div>
    </div>
    </div>
    )
}

export default Card1;

function WhatWeDoCard(props) {
  return (
    <div className = "what-we-do-card">
    <div className = "what-we-do-card-icon-wrapper">
      {props.icon}
    </div>
    <h3 className = "what-we-do-card-title">
      {props.title}
    </h3>
    <p className = "what-we-do-card-description">
        {props.text}
    </p>
    </div>
    )
}