import React,{useState,useEffect,useRef} from "react"
import { ReactComponent as MenuIcon } from "../../assets/svg/menu.svg"
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowdown.svg"
import data from "./data"
import {Link} from "react-router-dom"

function SmallScreenNavBar() {
const [isDropdownOpen,setIsDropdownOpen] = useState("")
const handleDropdownOpen = (dropper) => {
  setIsDropdownOpen(isDropdownOpen => {
    if (isDropdownOpen === dropper) {
      return ""
    }else {
      return dropper
    }
  })
}
const [isSmNavbarOpen,setIsSmNavbarOpen] = useState(false)
const MenuIconContainer = useRef(null);
const handleMenuIconClick = (event) => {
  setIsSmNavbarOpen(isSmNavbarOpen => !isSmNavbarOpen)
}
const handleClickOutside = event => {
    if (MenuIconContainer.current && !MenuIconContainer.current.contains(event.target)) {
      setIsSmNavbarOpen(false);
    }
  };
useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }); 
  return (
    
   <div className = {isSmNavbarOpen ? "sm-navbar" : "sm-navbar"} ref ={MenuIconContainer}>
       <MenuIcon className ="menu-icon" onClick = {handleMenuIconClick}  />
       
       {isSmNavbarOpen && 
    <div className ={`sm-navbar-menu ${isSmNavbarOpen?"sm-navbar-menu-animate" : ""}`}>
       {data.map((itm,i) => {
         if(itm.link && !itm?.data?.length) {
           return (
        <Navigator {...itm} handleMenuIconClick = {handleMenuIconClick} key = {itm.name} />           )
         }  else {
         return (
         <div className = "sm-navbar-dropdown-wrapper" key = {itm.name} >
         <div className = "sm-navbar-dropper" onClick = {() => handleDropdownOpen(itm.name)}>
         <div className ="sm-navbar-link">
            {itm.icon}
             <div className ="sm-navbar-text">
               {itm.name}
             </div>
          </div>
             <ArrowDownIcon className = {`arrow-down-icon ${isDropdownOpen === itm.name ? "arrow-down-open" : ""}`}/>
         </div>
          {
            isDropdownOpen === itm.name &&
            <Dropdown dropper = {itm} handleMenuIconClick = {handleMenuIconClick} isOpen = {isDropdownOpen === itm.name}/>
          }
         </div>
         )
       }
       })}
     
    </div>
 }
  </div>
    )
}
export default SmallScreenNavBar;

function Dropdown(props) {
  return(
    <div className = "sm-navbar-dropper">
      <div className = {` sm-navbar-droping-wrapper ${props.isOpen?"sm-drop-down-animate":""}`}>
      {props.dropper.data.map((itm,i) => {
      return(
        <Navigator {...itm} handleMenuIconClick = {props.handleMenuIconClick} key = {itm.name}/>
        )
      })}
      </div>
    </div>
    )
}

function Navigator(props) {
  return(
      <Link to = {props.link} className = "sm-navbar-link" onClick = {props.handleMenuIconClick}>
               {props.icon}
               <div className ="sm-navbar-text">
               {props.name}
               </div>
      </Link>
    )
}