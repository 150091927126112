import React,{useState} from "react"
import SEO from "../SEO/SEO"
import {useParams,useNavigate,Link} from "react-router-dom"
import img from "../../assets/img/pages/gallery.jpg"
import useFetchClient from "../hooks/useFetchClient"
import { ReactComponent as Expand} from "../../assets/svg/expand.svg" 
import { ReactComponent as Close } from "../../assets/svg/close.svg" 
import Error from "../404/Error"
import "./latest.css"
import Skeleton from "react-loading-skeleton";
function Gallery() {
const {data,error,loading} = useFetchClient("/admin/album");
let [image,setImage] = useState("")
  if(error) {
    return <Error/>
  }
 if(data) {
   data.data.sort((a,b) => -Number(new Date(a.date)) + Number(new Date(b.date)) )
  }
  return(
    <>
    <GalleryWelcome/>
    <div className = "gallery-wrapper">
       <div className = "gallery-flex">
       { data && 
     <>
    <SEO 
    title = "ALBUMS"
    keywords = "Album,holistic gallery,Celebration Gallery,kolfe mulu wengel Holistic Gallery,kfgc, Event Gallery and albums,KFGC holistic Album"
    description = "Albums and Gallery of fundraising Events and other Events Gallery in Holistic team "
    />
     { data.data.map((album,i) => (
     <React.Fragment key = {album.title+"-sample"}>
      <GalleryCard img = {album.images[0]} setImage = {setImage}/>
        <GalleryCard img = {album.images[1]}  setImage = {setImage}/>
    </React.Fragment>
       ))
     }
      </>
     }
    { loading && 
      <>
      <SkeletonItem/>
      <SkeletonItem/>
      </>
    }
       </div>
    </div>
    <div className = "album-wrapper">
       <div className = "album">
           <h3 className = "oswald album-title">
     See Full Album ...
     </h3>
       {data && data.data.map((album,i) => (
         <AlbumCard  key = {album.id} album = {album}/>
       ))}
     { loading && 
      <>
      <SkeletonItem/>
      <SkeletonItem/>
      </>
    }
      </div>
    </div>
  {image !== "" && <GalleryPopup setImage = {setImage} image = {image} /> }
    </>
    )
}

export default Gallery;

function GalleryWelcome() {
  return (
    <div className = "gallery-welcome">
       <img src = {img} className = "gallery-welcome-img" alt = "" loading = "lazy" />
     <div className = "gallery-welcome-content-wrapper">
       <div className = "gallery-welcome-content">
       <div className = "gallery-welcome-title-wrapper">
          <h2 className = "gallery-welcome-title oswald">
             Gallery
          </h2>
          </div>
          <div className = "gallery-welcome-text-wrapper">
          <p className = "gallery-welcome-text">
            Our Memories
          </p>
          </div>
       </div>
       </div>
    </div>
    )
}

function GalleryCard(props) {
  return(
      <div className = "gallery-card">
         <img src = {process.env.PUBLIC_URL + props.img.path}  alt = "" className = "gallery-card-img" loading = "lazy"/>
         <div className = "gallery-overlay">
         <div className = "gallery-overlay-icon-wrapper">
            <Expand className = "gallery-overlay-icon" onClick = {() => props.setImage(props.img.path)}/>
            </div>
         </div>
      </div>
    )
}

function GalleryPopup(props) {
  return (
    <div className = "gallery-popup-wrapper">
      <div className = "gallery-popup">
      <div className = "gallery-popup-killer">
    <Close className = "gallery-popup-killer-icon" onClick = {() => props.setImage("")}/>
      </div>
         <img src = {process.env.PUBLIC_URL + props.image}  alt = "" className = "gallery-popup-img" loading = "lazy"/>
      </div>
    </div>
    )
}


function AlbumCard(props) {
  const navigator = useNavigate()
  return (
    <div className = "album-card" onClick = {() => navigator("/gallery/"+props.album.id)}>
    <img src = {process.env.PUBLIC_URL + props.album.images[0].path}  alt = "" className = "album-card-img" loading = "lazy"/>
    
    <div className = "album-card-overlay">
    <div className = "album-card-overlay-content">
       <h3 className = "oswald album-card-overlay-title">
          {props.album.title}
       </h3>
       <p className = "album-card-overlay-text ubuntu">
          {props.album.images.length} photos
       </p>
      </div>
    </div>
    </div>
    )
}

export function OneAlbumGallery(props) {
  let [image,setImage] = useState("")
  const {album} = useParams()
  const {data,error,loading} = useFetchClient("/admin/album/" + album);
  if(error) {
    return <Error/>
  }

  return (
    <>
      <div className = "gallery-wrapper">
        <div className = "album-gallery-topic">
        <h3 className = "album-gallery-title">
          { data && data.data[0].title}
          {loading && 
        <Skeleton duration={1} height={56}  />
          }
          
        </h3>
        <div className = "album-gallery-back-wrapper">
        <Link to = "/gallery" className = "album-gallery-back">
      《 Back to Gallery
        </Link>
        </div>
        </div>
       <div className = "gallery-flex">
       {data && JSON.parse(data.data[0].images).map(albumImg => (
     <GalleryCard img = {albumImg} key = {albumImg.path} setImage = {setImage}/>
         ))}
    { loading && 
      <>
      <SkeletonItem/>
      <SkeletonItem/>
      <SkeletonItem/>
      <SkeletonItem/>
      </>
    }
       </div>
      <div className = "album-gallery-topic">  
      <Link to = "/gallery" className = "album-gallery-back">
        《 Back to Gallery
        </Link>
        </div>
    </div>
     {image !== "" && <GalleryPopup setImage = {setImage} image = {image} /> }
    </>
    )
}

const SkeletonItem = () => {
  return (
    <>
        {Array(4)
          .fill()
          .map((item, index) => (
            <li className="gallery-skeleton-card card" key={"skeleton-"+index}>
              <Skeleton height={160}  />
            </li>
          ))}
    </>
  );
};