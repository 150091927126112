import React,{useState} from "react"
import SEO from "../SEO/SEO"
import img from "../../assets/img/pages/contact.jpg"
import { ReactComponent as Email } from "../../assets/svg/email.svg" 
import axios from "../axiosSetup/axiosSetup"
import { ReactComponent as Phone} from "../../assets/svg/call.svg" 
import data from "../../data"
import "./Aboutus.css"
function Contact() {
  return(
    <>
      <SEO 
    title = "Contact Us"
    keywords = "Holistic Team Contact ,KFGC Holistic Send Messsage,kolfe mulu wengel Holistic Contact Info,Supporting kolfe mulu wengel founders contact,Holistic Services team Suggestion,Holistic Services team visionaries contact ,Holistic team Contact us , Holistic send Us message"
    description = "Kolfe mulu wengel (KFGC) Holistic team Contact us."
    />
     <ContactWelcome/>
     <div className = "conflict-contact-wrapper">
     <main className = "contact">
       <ContactInfo/>
       <ContactForm/>
     </main>
     </div>
    </>
    )
}
function ContactWelcome() {
  return (
    <>
    <div className = "conflict-contact-welcome">
       <img src = {img} className = "conflict-contact-welcome-img" alt = ""/>
     <div className = "conflict-contact-welcome-content-wrapper">
       <div className = "conflict-contact-welcome-content">
       <div className = "conflict-contact-welcome-title-wrapper">
          <h2 className = "conflict-contact-welcome-title oswald">
             Contact Us
          </h2>
      </div>
      <div className = "conflict-contact-welcome-text-wrapper">
          <p className = "conflict-contact-welcome-text">
             we would love to hear from you.
          </p>
        </div>
       </div>
       </div>
    </div>
    </> 
    )
}
function ContactInfo() {
  return(
    <div className = "conflict-contact-info">
        <h2 className = "conflict-contact-info-title roboto">
           Contact <span className = "conflict-contact-info-itself">Info</span>
        </h2>
       <div className = "conflict-contact-information">
           <div className = "conflict-contact-information-title">
             <Email className = "conflict-contact-information-icon" />
              Email
              </div>
             <p className = "conflict-contact-information-data">
              <a className = "mail-link" href = "mailto:kfgchurchholistic@gmail.com" >
                {data.email} 
              </a>
              </p>
     </div>
         <div className = "conflict-contact-information">
           <div className = "conflict-contact-information-title">
             <Phone className = "conflict-contact-information-icon" />
              Phone
              </div>
             <p className = "conflict-contact-information-data">
           <a className = "mail-link" href={"tel:+251" + data.phone.slice(1,-1)}> 
               {data.phone} 
          </a>
             </p>
     </div>
     
    </div>
    )
}


function ContactForm() {
const [name,setName] = useState("")
const handleNameChange = e => {
  setName(e.target.value)
}
const [email,setEmail] = useState("")
const handleEmailChange = e => {
  setEmail(e.target.value)
}
const [subject,setSubject] = useState("")
const handleSubjectChange = e => {
  setSubject(e.target.value)
}
const [message,setMessage] = useState("")
const handleMessageChange = e => {
  setMessage(e.target.value)
}


const [loading,setLoading] = useState(false)
const [isDone,setIsDone] = useState(false)
const [error,setError] = useState(false)

const [isInvalid,setIsInvalid] = useState(false)
const handleSubmit = async e => {
  if(name.length < 2 || email.length < 3 || subject.length < 2 || message.length < 5)  {
    return setIsInvalid(true)
  }
  setIsInvalid(false)
  const data = {name,email,message,subject};
  
  try {
  setLoading(true)
 await axios.post("/admin/contact-message",data);
setLoading(false)
setIsDone(true)
setName("")
setEmail("")
setSubject("")
setMessage("")
setTimeout(() => {
    setIsDone(false)
  },2500)
}catch (err) {
  setLoading(false)
  setError(true)
setName("")
setEmail("")
setSubject("")
setMessage("")
  setTimeout(() => {
    setError(false)
  },2500)
}
}
  return(
    <div className = "">
      <div className = "contact-form-title roboto" >
        Send us Your <span className = "contact-form-title-itself" >Feedback</span>
      </div>
      
      <div className = "conflict-contact-form">
        <div className = "contact-form-input-wrapper">
         <input className = "contact-form-input" type = "text" placeholder = "Name" value = {name} onChange = {handleNameChange} />
         <input className = "contact-form-input" type = "email" placeholder = "Email" value = {email} onChange = {handleEmailChange} />
         </div>
         <input className = "contact-form-subject" type = "text" placeholder = "Subject" value = {subject} onChange = {handleSubjectChange} />
         <textarea className = "contact-form-textarea" placeholder = "Leave us your Messsage here..." value = {message} onChange = {handleMessageChange} >
         
         </textarea>
         {isInvalid && <div className = "contact-form-invalid">
         Please fill the fields appropriately..
         </div>}
         {isDone && <div className = "contact-form-success">
         Thanks for your message we will respond soon..
         </div>} 
         {error && <div className = "contact-form-invalid">
           Unable to send your message...
         </div>}
         <button type = "button" className = "contact-form-button roboto" onClick = {handleSubmit}>
            {loading ? "Sending..." : "Send Message"}
         </button>
      </div>
    </div>
    )
}


export default Contact;