import React from "react"
import {useNavigate} from "react-router-dom";
import img from "../../assets/img/supportnow/worship.jpg"
function SupportNow() {
const navigator = useNavigate()
  return (
    <div className = "support-now-wrapper">
      <div className = "support-now">
         <div className = "support-now-img-wrapper">
         <img src = {img} alt = "" className = "support-now-img" loading = "lazy" />
         </div>
         <div className = "support-now-content">
         <h2 className = "support-now-title oswald">
         Support Now
         </h2>
         <div className = "support-now-button-holder">
         <button className = " support-now-button support-now-partener-button roboto" onClick = {() => navigator("/partener")}>
           Be Partener
         </button>
        <button className = "support-now-button support-now-support-button roboto" onClick = {() => navigator("/donate")}>
           Support Now
         </button>
         </div>
         </div>
      </div>
    </div>
    )
}

export default SupportNow;