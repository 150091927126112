import React from "react"
import homeless from "../../../assets/img/programs/homeless/hall-homeless.jpg"
import clothes from "../../../assets/img/programs/homeless/clothes.jpg"
import "./program.css"
function SupportHomelessDetail() {
  return (
    <>
    <ProgramDetailContent/>
    </>
    )
}
export default SupportHomelessDetail;

function ProgramDetailContent() {
  return (
   <div className = "one-program-detail-wrapper">
      <div className = "one-program-detail">
      <ProgramDetailHeader/>
      <ProgramDetailDefination/>
      <OneProgramDetailMiddleDefination/>
      </div>
  </div>
    )
}
function ProgramDetailHeader() {
  return (
    <div className = "one-program-detail-topic">
       <div className = "one-program-detail-topic-breadcrumbs">
       <span className = "one-program-breadcrumbs-title">Our Programs</span>
       <span className = "one-program-breadcrumbs-dot">●</span>
      <span className = "one-program-breadcrumbs-title">Programs</span>
      <span className = "one-program-breadcrumbs-dot">●</span>
     <span className = "one-program-breadcrumbs-title">Supporting homeless</span>
       </div>
       <h3 className = "one-program-title">
    Supporting homeless People's 
       </h3>
    </div>
    )
}
function ProgramDetailDefination() {
  return (
    <div className = "one-program-description-wrapper">
       <div className = "one-program-description roboto">
       
          <div className = "one-program-detail-paragraph">
Kolfe Mulu Wengel Holistic Service is a compassionate volunteer organization dedicated to supporting homeless individuals. The organization facilitates events aimed at providing essential life needs such as food, clothing, and other basic items to those experiencing homelessness. They collect donations from willing individuals who wish to contribute and support those in need, ensuring that the necessary resources reach the homeless community.
       </div>
   <div className = "one-program-detail-paragraph">
       </div>
       </div>
       <div className = "one-program-description-img-wrapper">
       <img src = {homeless} className = "one-program-description-img" alt = "" loading = "lazy"/>
       </div>
    </div>
    )
}

function OneProgramDetailMiddleDefination() {
  return (
    <div className = "one-program-middle-defination-wrapper">
   <div className = "one-program-middle-description">
   <div className = "one-program-detail-paragraph">
In addition to physical donations, Kolfe Mulu Wengel Holistic Service also collects funds from individuals in the Kolfe Mulu Wengel Church and other generous donors. These funds are utilized to provide financial assistance and support to homeless individuals, helping them meet their economic needs and overcome financial hardships. The organization recognizes that addressing the economic challenges faced by homeless individuals is crucial for their overall well-being and stability.
       </div>
   <div className = "one-program-detail-paragraph">
Furthermore, Kolfe Mulu Wengel Holistic Service extends its support to individuals within the Kolfe Mulu Wengel Church community. They provide assistance and guidance in economic activities, empowering individuals to improve their financial situations and enhance their livelihoods. By offering support in various economic endeavors, the organization helps individuals achieve greater stability and self-sufficiency.
</div>
 <div className = "one-program-detail-paragraph">
In summary, Kolfe Mulu Wengel Holistic Service is committed to supporting homeless individuals by providing essential resources, collecting donations, and offering financial assistance. Their efforts not only address the immediate needs of those experiencing homelessness but also foster a sense of community and support within the Kolfe Mulu Wengel Church and beyond. By empowering individuals through economic activities, the organization enables them to improve their financial circumstances and create a path towards a more secure future.
     </div>
  </div>
  <div className = "one-program-middle-img-wrapper">
  <img src = {clothes}  className = "one-program-middle-img" alt = "" loading = "lazy"/>
  </div>
    </div>
    )
}