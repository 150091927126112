import React,{useState,useRef} from "react"
import img from "../../assets/img/iframe/iframe-black.jpg"
import { ReactComponent as Close } from "../../assets/svg/close.svg" 
import { ReactComponent as Play } from "../../assets/svg/play.svg" 
import data from "../../data"
function Iframe() {
 const iframeIcon = useRef(null)
 const iframeWrapper = useRef(null)
const [iframeOpen,setIframeOpen] = useState(false)
const iframeVideoController = () => {
  setIframeOpen(true)
}
const iframeVideoClose = (e) => {

  if(e.target.tagName === "rect" || e.target.id === iframeWrapper.current.id) {
    setIframeOpen(false)
  }
}
  return(
    <>
     <div className = "iframe-wrapper">
       <img className = "iframe-img" src = {img} alt = "" loading = "lazy"/>
       <div className = "iframe-overlay">
         <div className = "iframe-overlay-content">
             <h2 className = "iframe-overlay-title roboto">
                 Discover more About Holistic service Team
             </h2>
<Play className = "iframe-overlay-icon" onClick = {iframeVideoController}/>
        </div>
       </div>
    </div>
    {iframeOpen && <IframeVideo
    iframeOpen = {iframeOpen}
    iframeIcon = {iframeIcon} 
    iframeWrapper = {iframeWrapper}
    iframeVideoClose = {iframeVideoClose}/>}
    </>
    )
}

export default Iframe;

function IframeVideo(props) {
  return(
    <div className = {`${props.iframeOpen ? "iframe-video-wrapper-animate": ""} iframe-video-wrapper`} id = "iframe-video-wrapper" ref = {props.iframeWrapper} onClick = {props.iframeVideoClose}>
       <div className = "iframe-video-content">
           <Close className = "iframe-close-icon" id = "iframe-close-icon" ref = {props.iframeIcon} onClick = {props.iframeVideoClose} name = "svg"/>
          <iframe title = "Holistic Team" src={data.iframe}  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowfullscreen  className = "iframe-video">
           </iframe>
       </div>
    </div>
    )
}