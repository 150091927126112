import React from "react"
import dateFormat from "dateformat";
import useFetch from "../../hooks/useFetch"
import Loading from "../Loading"
import FetchError from "../FetchError"
import {axiosProtected as axios}from "../../axiosSetup/axiosSetup";
import {useNavigate,useParams} from "react-router-dom";
import "./feedback.css"
function Feedback() {
  const {data:resp,loading,error} = useFetch("/admin/contact-message");
 if(loading) {
   return <Loading/>
 }
 if (error) {
   return <FetchError/>
 }
 
 if(resp) {
if(resp.data) {
   resp.data.sort((a,b) => -Number(new Date(a.date)) + Number(new Date(b.date)) )
  }
  return (
    <div className = "admin-feedback-wrapper">
       <h2 className = " oswald admin-feedback-title">
        Feedbacks From People's 
       </h2>
       {resp.data.length ? resp.data.map(data => (
       <MessageBlock key = {data.id} data = {data}/>
       )) : <div className = "no-feedback-yet">"No Feedback yet..."</div>}
    
 </div>
    )
 }
}
export default Feedback;

function MessageBlock(props) {
const navigator  = useNavigate()
const handleClick =  (e,id) => {
  axios.patch("/admin/contact-message/"+ id)
  .then(res => {
  })
  .catch(err => {
    
  })
  navigator(`/admin/feedback-message/${props.data.id}`)
}
return (
       <div className = "feedback-block" onClick = {(e) =>  handleClick(e,props.data.id)}>
         <p className = "feedback-label">
            {props.data.name}
         </p>
       <div className = "feedback-message">
       
        {props.data.message.slice(0,props.data.message.length < 20 ? props.data.message.length: 20 )}...
       </div>
       <div className = "feedback-read-status-wrapper">
       {props.data.seen === 0 &&
        <div className = "feedback-read-status">
          
         </div>
       }
       </div>
       </div>
    )
}

export function EachFeedback() {
  const {id} = useParams()
  const navigator = useNavigate()
  const {data:resp,loading,error} = useFetch("/admin/contact-message/"+id);
 if(loading) {
   return <Loading/>
 }
 if (error) {
   return <FetchError/>
 }
 
 if(resp) {
  const message = resp.data[0]
   return (
     <div className = "admin-each-message">
     <div className = "admin-each-message-block admin-each-message-block-flex">
        <div className = "each-message-block-title">
        Name :
        </div>
        <div className = "each-message-block-value">
        {message.name}
        </div>
     </div>
        <div className = "admin-each-message-block   ">
        <div className = "each-message-block-title">
        From :
        </div>
        <div className = "each-message-block-value">
        {message.email}
        </div>
     </div>
   <div className = "admin-each-message-block admin-each-message-block-flex">
        <div className = "each-message-block-title">
        Date :
        </div>
        <div className = "each-message-block-value">
            {dateFormat(new Date(message.date), "ddd, mmm dd, yyyy")}
        </div>
     </div>
   <div className = "admin-each-message-block">
        <div className = "each-message-block-title">
        Message :
        </div>
        <div className = "each-message-block-value-message roboto">
        {message.message}
        </div>
     </div>
     <button className = "each-message-back" onClick = {() => navigator("/admin/feedback-message")}>
       Back
     </button>
     </div>
     )
 }

  
}