import React, { useState } from "react";
import { axiosProtected as axios } from "../../axiosSetup/axiosSetup";
import useFetch from "../../hooks/useFetch";
import Popup from "../Popup";
import Loading from "../Loading";
import FetchError from "../FetchError";
import "./update.css";
import { useNavigate } from "react-router-dom";
import { useProviderContext } from "../provider/adminProvider";
function SendMessage() {
  const { logoutAdmin } = useProviderContext();
  const navigator = useNavigate();
  const {
    data: resp,
    loading: loadingPage,
    error: errorPage,
  } = useFetch("/admin/daily-update");
  const [title, setTitle] = useState("");
  const [isInvalidTitle, setIsInvalidTitle] = useState(false);
  const handleTitleChange = (e) => {
    setTitle(e.target.value);

    if (title.trim().length < 4) {
      setIsInvalidTitle(true);
    } else {
      setIsInvalidTitle(false);
    }
  };
  const [text, setText] = useState("");
  const [isInvalidText, setIsInvalidText] = useState(false);
  const handleTextChange = (e) => {
    setText(e.target.value);

    if (text.trim().length < 10) {
      setIsInvalidText(true);
    } else {
      setIsInvalidText(false);
    }
  };

  const [loading, setLoading] = useState(false);
  const [posted, setPosted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAllInvalid, setIsAllInvalid] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title.trim().length < 4 || text.trim().length < 10) {
      return setIsAllInvalid(true);
    }
    setIsAllInvalid(false);
    const data = { subject: title, text };
    try {
      setLoading(true);
      await axios.post("/admin/send-daily-update", data);
      setLoading(false);
      setPosted(true);
      setTimeout(() => {
        setPosted(false);
      }, 5000);
      setTitle("");
      setText("");
    } catch (err) {
      if (err?.response?.status === 419) {
        logoutAdmin();
        navigator("/admin/abux-security/login");
        return;
      }

      setLoading(false);
      setError(true);
      setErrorMessage(err.message);
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  };
  if (loadingPage) {
    return <Loading />;
  }

  if (errorPage) {
    return <FetchError />;
  }

  if (resp) {
    return (
      <div className="admin-send-message-wrapper">
        {posted && (
          <Popup
            message={"Daily Updates Sent Successfully."}
            type="popup-success"
          />
        )}
        {error && <Popup message={errorMessage} type="popup-error" />}

        <h2 className=" oswald admin-send-message-title">
          Send Email for Users
        </h2>
        <p className="admin-send-message-users">
          {resp.data.length} Registered Emails
        </p>
        <div className="send-message-form-block">
          <p className="send-message-form-label">Subject *</p>
          <div className="send-message-form-input-wrapper">
            <input
              type="text"
              className="send-message-form-input"
              placeholder="Message Subject"
              value={title}
              onChange={handleTitleChange}
            />
            {isInvalidTitle && (
              <p className="admin-invalid-input">Please fill valid subject..</p>
            )}
          </div>
        </div>
        <div className="send-message-form-block">
          <p className="send-message-form-label">Message *</p>
          <div className="send-message-form-textarea-wrapper">
            <textarea
              className="send-message-form-textarea roboto"
              placeholder="Message text..."
              value={text}
              onChange={handleTextChange}
            ></textarea>
            {isInvalidText && (
              <p className="admin-invalid-input">please put message...</p>
            )}
          </div>
        </div>
        {isAllInvalid && (
          <p className="admin-invalid-input">
            Make sure to enter valid informations..
          </p>
        )}
        <div className="send-message-form-block">
          <button
            className={`${
              loading ? "loading-button" : ""
            } send-message-form-button `}
            type="button"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Loading..." : "Send Message"}
          </button>
        </div>
      </div>
    );
  }
}
export default SendMessage;
