import React from "react"
import SEO from "../../SEO/SEO"
import playing from "../../../assets/img/programs/child/playing.jpg"
import praying from "../../../assets/img/programs/child/praying.jpg"
import "./program.css"

function ChildCareDetail() {
  return (
    <>
      <SEO 
    title = "PROGRAMS - Orphange Care"
    keywords = "Programs,KFGC Holistic programs,kolfe mulu wengel Holistic programs,Supporting kolfe mulu wengel,Holistic Services,Holistc orphange care,child care"
    description = "Kolfe mulu wengel (KFGC) Holistic team Programs and services to support Orphange children...."
    />
    <ProgramDetailContent/>
    </>
    )
}
export default ChildCareDetail;

function ProgramDetailContent() {
  return (
   <div className = "one-program-detail-wrapper">
      <div className = "one-program-detail">
      <ProgramDetailHeader/>
      <ProgramDetailDefination/>
      <OneProgramDetailMiddleDefination/>
      </div>
  </div>
    )
}
function ProgramDetailHeader() {
  return (
    <div className = "one-program-detail-topic">
       <div className = "one-program-detail-topic-breadcrumbs">
       <span className = "one-program-breadcrumbs-title">Our Programs</span>
       <span className = "one-program-breadcrumbs-dot">●</span>
      <span className = "one-program-breadcrumbs-title">Programs</span>
      <span className = "one-program-breadcrumbs-dot">●</span>
     <span className = "one-program-breadcrumbs-title">child care</span>
       </div>
       <h3 className = "one-program-title">
    Caring and Guiding children's 
       </h3>
    </div>
    )
    
}
function ProgramDetailDefination() {
  return (
    <div className = "one-program-description-wrapper">
       <div className = "one-program-description roboto">
       
          <div className = "one-program-detail-paragraph">
Kolfe Mulu Wengel Holistic Service is a compassionate volunteer organization dedicated to caring for and guiding children. Through their unwavering commitment, they provide invaluable support and mentorship to children in need. The organization offers a range of services aimed at nurturing and empowering children, including counseling, advice, regular visits, and engaging with orphanages. They also extend their support by providing educational materials, offering extra time for educational assistance, and organizing special birthday celebrations for the children they serve.
       </div>
   <div className = "one-program-detail-paragraph">
   Counseling plays a vital role in the organization's efforts to support children. Trained professionals offer counseling services to address emotional and psychological challenges faced by children, creating a nurturing and safe space for them to express their thoughts and concerns. Through counseling, children receive guidance and coping strategies, helping them navigate through difficult circumstances and develop resilience.
       </div>
       </div>
       <div className = "one-program-description-img-wrapper">
       <img src = {playing} className = "one-program-description-img" alt = "" loading = "lazy"/>
       </div>
    </div>
    )
}

function OneProgramDetailMiddleDefination() {
  return (
    <div className = "one-program-middle-defination-wrapper">
   <div className = "one-program-middle-description">
   <div className = "one-program-detail-paragraph">
In addition to counseling, Kolfe Mulu Wengel Holistic Service believes in the power of education to transform lives. They provide educational materials and resources to support children's learning and academic growth. Volunteers also dedicate extra time to assist children with their studies, ensuring they receive the necessary guidance and academic support. By investing in education, the organization empowers children with the knowledge and skills they need to build a brighter future.
       </div>
   <div className = "one-program-detail-paragraph">
Furthermore, the organization organizes visits to orphanages, creating opportunities for children to interact, form connections, and experience joy. These visits not only bring happiness to the children but also provide them with a sense of belonging and emotional support. By spending quality time with the children and celebrating their birthdays, Kolfe Mulu Wengel Holistic Service shows them that they are valued and cared for, boosting their self-esteem and emotional well-being.
</div>
<div className = "one-program-detail-paragraph">
In summary, Kolfe Mulu Wengel Holistic Service stands as a beacon of hope for children in need of care and guidance. Through counseling, educational support, visits, and special celebrations, the organization nurtures and empowers children, helping them overcome challenges and fostering a sense of belonging. By investing in their emotional well-being, education, and personal growth, Kolfe Mulu Wengel Holistic Service positively impacts the lives of children, providing them with the support they need to thrive and succeed.
     </div>
  </div>
  <div className = "one-program-middle-img-wrapper">
  <img src = {praying}  className = "one-program-middle-img" alt = "" loading = "lazy"/>
  </div>
    </div>
    )
}