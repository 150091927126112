import React from "react"
import SEO from "../../SEO/SEO"
import sharing from "../../../assets/img/programs/blood/sharing-5m.jpg"
import showing from "../../../assets/img/programs/blood/showing.jpg"
import "./program.css"


function BloodDonationDetail() {
  return (
    <>
     <SEO 
    title = "OUR PROGRAMS - Blood Donation"
    keywords = "Programs,KFGC Holistic programs,kolfe mulu wengel Holistic programs,Supporting kolfe mulu wengel,Holistic Services, Blood Donation ,Kfgc blood"
    description = "Kolfe mulu wengel (KFGC) Holistic team Programs and services to support blood capacity in our...."
    />
    <ProgramDetailContent/>
    </>
    )
}
export default BloodDonationDetail;

function ProgramDetailContent() {
  return (
   <div className = "one-program-detail-wrapper">
      <div className = "one-program-detail">
      <ProgramDetailHeader/>
      <ProgramDetailDefination/>
      <OneProgramDetailMiddleDefination/>
      </div>
  </div>
    )
}
function ProgramDetailHeader() {
  return (
    <div className = "one-program-detail-topic">
       <div className = "one-program-detail-topic-breadcrumbs">
       <span className = "one-program-breadcrumbs-title">Our Programs</span>
       <span className = "one-program-breadcrumbs-dot">●</span>
      <span className = "one-program-breadcrumbs-title">Programs</span>
      <span className = "one-program-breadcrumbs-dot">●</span>
     <span className = "one-program-breadcrumbs-title">blood donation</span>
       </div>
       <h3 className = "one-program-title">
       Blood Donation
       </h3>
    </div>
    )
}
function ProgramDetailDefination() {
  return (
    <div className = "one-program-description-wrapper">
       <div className = "one-program-description roboto">
       
          <div className = "one-program-detail-paragraph">
Kolfe Mulu Wengel Holistic Service is a volunteer organization dedicated to promoting and facilitating blood donation. The organization actively works towards creating awareness and educating the public about the importance of blood donation. They organize events and educational programs to provide information about the impact of blood donation on saving lives and serving individuals in need.
       </div>
   <div className = "one-program-detail-paragraph">
    Through their events, Kolfe Mulu Wengel Holistic Service encourages community members to participate in blood donation drives. These events serve as a platform for individuals to contribute to the well-being of others and make a significant difference in their lives. The organization ensures that the donation process is smooth and efficient, making it accessible for donors and maximizing the impact of their contributions.
       </div>
       </div>
       <div className = "one-program-description-img-wrapper">
       <img src = {showing} className = "one-program-description-img" alt = ""/>
       </div>
    </div>
    )
}

function OneProgramDetailMiddleDefination() {
  return (
    <div className = "one-program-middle-defination-wrapper">
   <div className = "one-program-middle-description">
   <div className = "one-program-detail-paragraph">
Furthermore, Kolfe Mulu Wengel Holistic Service maintains scheduled programs to support ongoing blood donation activities. They collaborate with healthcare institutions and blood banks to coordinate donation campaigns and ensure a steady supply of blood for those in need. The organization actively engages with donors, providing them with the necessary information and support throughout the donation process.
       </div>
   <div className = "one-program-detail-paragraph">
In summary, Kolfe Mulu Wengel Holistic Service plays a vital role in promoting and facilitating blood donation. Through their events, educational programs, and scheduled activities, they create awareness, educate the public, and support the ongoing need for blood donations. By encouraging community members to participate and contribute, the organization saves lives and serves individuals in need, fostering a sense of collective responsibility and compassion within the community.
     </div>
  </div>
  <div className = "one-program-middle-img-wrapper">
  <img src = {sharing}  className = "one-program-middle-img" alt = ""/>
  </div>
    </div>
    )
}