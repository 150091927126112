import React from "react"
import { ReactComponent as NotFound } from "../../assets/svg/404.svg"
import {useNavigate} from "react-router-dom"
import "./404.css"
function Error() {
  const navigator = useNavigate();
  return(
    <div className = "error-wrapper">
       <div className = "error">
          <h2 className = "error-big-text">
             Page Not Found.
          </h2>
          <h1 className = "error-title oswald">
            <NotFound className = "error-icon"/>  404
          </h1>
          <div className = "error-message">
          page not found return to home page
          </div>
          <button className = "error-button" onClick = {() => navigator("/")}>
            《 Back to Home
          </button>
       </div>
    </div>
    )
}

export default Error;