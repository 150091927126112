import React,{useState,useRef,useEffect} from "react"
import {Link} from "react-router-dom"
import Dropdown from "./Dropdown"
import { ReactComponent as ArrowDownIcon } from "../../assets/svg/arrowdown.svg"
function NavbarItem(props) {
const dropDownerContainer = useRef(null)
const [isDropDownOpen,setIsDropDownOpen] = useState(false) 
const handleClickDropDown = (event) => {
  setIsDropDownOpen(isDropDownOpen => !isDropDownOpen)
}
const handleClickOutside = event => {
    if (dropDownerContainer.current && !dropDownerContainer.current.contains(event.target)) {
     setIsDropDownOpen(false)
    }
    
  }; 
useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  
  if(props.link && !props?.data?.length) {
      return (
   <div className ="one-menu-item-wrapper">
    <Link to = {props.link} className = "menu-item">
       {props.icon}
       <div className ="menu-item-text">{props.name}</div>
    </Link>
    </div>
    )
  } 
  else {
    return (
    <div className ="one-menu-item-wrapper">
    <div className = "menu-item" ref = {dropDownerContainer} onClick = {handleClickDropDown} >
       {props.icon}
       <div className ="menu-item-text">{props.name}</div>
       <ArrowDownIcon className = {`arrow-down-icon ${isDropDownOpen ? "arrow-down-open" : ""}`}/>
  <div className = {`drop-down ${isDropDownOpen ? "animate-drop-down" : "drop-down-invisible"}`} >
  { props.data.map((itm,i) => {
     return (
   <Dropdown key = {itm.name} data = {[{
    name:itm.name ,
    icon:itm.icon,
    link:itm.link
     }]}/>
       )
     })
  }
     </div>
     </div>
    </div>
    )
    }
}
export default NavbarItem;