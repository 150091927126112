import React, { useState, useRef } from "react";
import SEO from "../SEO/SEO";
import img from "../../assets/img/pages/partener.jpg";
import { ReactComponent as PartenerIcon } from "../../assets/svg/partener.svg";
import axios from "../axiosSetup/axiosSetup";
import "./partener.css";
function Partener() {
  return (
    <>
      <SEO
        title="BE PARTENER"
        keywords="Holistic Team Register ,KFGC Holistic Be partener,kolfe mulu wengel Holistic be volunteer,Supporting kolfe mulu wengel volunteer,Holistic Services team partener registration,Holistic Services team partener registration,Holistic team Contact us and register , Holistic send Us message be partener,volunteer,partener"
        description="Kolfe mulu wengel (KFGC) Holistic team Be Volunteer."
      />
      <PartenerWelcome />
      <div className="partener-wrapper">
        <div className="partener">
          <FormTitle />
          <PartenerForm />
        </div>
      </div>
    </>
  );
}

export default Partener;

function PartenerWelcome() {
  return (
    <>
      <div className="partener-welcome">
        <img src={img} className="partener-welcome-img" alt="" loading="lazy" />
        <div className="partener-welcome-content-wrapper">
          <div className="partener-welcome-content">
            <div className="partener-welcome-title-wrapper">
              <h2 className="partener-welcome-title oswald">Be Partener</h2>
            </div>
            <div className="partener-welcome-text-wrapper">
              <p className="partener-welcome-text">
                We Wolud Love To welcome You.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function FormTitle() {
  return (
    <div className="partener-form-title-wrapper">
      <h3 className="partener-form-title oswald">Personal Information</h3>
    </div>
  );
}

function PartenerForm() {
  const maleRadio = useRef();
  const femaleRadio = useRef();
  const [isInvalid, setIsInvalid] = useState(false);
  const [loading, setLoding] = useState(false);
  const [posted, setPosted] = useState(false);
  const [error, setError] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const [donation, setDonation] = useState("");
  const [donationInterval, setDonationInterval] = useState("3_months");
  const [skill, setSkill] = useState("");

  const [sex, setSex] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.length > 3 && email.length > 5 && phoneNo.length > 5) {
      setLoding(true);
      axios
        .post("/admin/volunteer", {
          name,
          email,
          sex,
          phoneNo,
        })
        .then((res) => {
          setLoding(false);
          setPosted(true);
          setName("");
          setEmail("");
          setPhoneNo("");
          setSex("");
          maleRadio.current.checked = false;
          femaleRadio.current.checked = false;
          setTimeout(() => {
            setPosted(false);
          }, 3000);
        })
        .catch((err) => {
          setLoding(false);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 3000);
        });
    } else {
      setIsInvalid(true);
      setTimeout(() => {
        setIsInvalid(false);
      }, 3000);
    }
  };
  return (
    <div className="partener-form-holder">
      <form className="partener-form">
        <div
          className={
            posted ? "good-stat status" : error ? "bad-stat status" : "status"
          }
        >
          {posted && "You are registered as Patener!"}
          {error && "Faild to registered please try later"}
        </div>
        <div className="partener-form-block">
          <p className="partener-form-label">Full Name *</p>
          <div className="partener-form-input-wrapper">
            <input
              placeholder="Your Name"
              className="partener-form-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
          </div>
        </div>

        <div className="partener-form-block">
          <p className="partener-form-label">Email*</p>
          <div className="partener-form-input-wrapper">
            <input
              placeholder="name@example.com"
              className="partener-form-input"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="partener-form-block">
          <p className="partener-form-label">Phone Number *</p>
          <div className="partener-form-input-wrapper">
            <input
              placeholder="09********"
              className="partener-form-input"
              type="number"
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value)}
            />
          </div>
        </div>

        <div className="partener-form-block">
          <p className="partener-form-label">Gender *</p>
          <div className="partener-form-input-wrapper">
            <div className="partener-form-radio-block">
              <div className="partener-form-radio-wrapper">
                <input
                  className="partener-form-radio"
                  type="radio"
                  name="gender"
                  onChange={(e) => setSex("M")}
                  ref={maleRadio}
                />
                <p className="partener-form-radio-text">Male</p>
              </div>
              <div className="partener-form-radio-wrapper">
                <input
                  className="partener-form-radio"
                  type="radio"
                  name="gender"
                  onChange={(e) => setSex("F")}
                  ref={femaleRadio}
                />
                <p className="partener-form-radio-text">Female</p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            margin: "1rem .2rem",
          }}
        >
          <p
            className="partener-form-label"
            style={{
              fontWeight: "bold",
            }}
          >
            Please enter the amount you wish to contribute toward this
            initiative.
          </p>
          <div className="partener-form-block">
            <div className="partener-form-input-wrapper">
              <input
                placeholder="Amount in ETB"
                className="partener-form-input"
                type="number"
                min={50}
                value={donation}
                onChange={(e) => setDonation(e.target.value)}
              />
            </div>
          </div>

          <p
            className="partener-form-label"
            style={{
              fontWeight: "bold",
            }}
          >
            Choose Your Donation Interval
          </p>
          <div className="partener-form-block">
            <select
              value={donationInterval}
              onChange={(e) => {
                setDonationInterval(e.target.value);
              }}
              style={{
                width: "100%",
                padding: "10px 15px",
                borderRadius: "8px",
                border: "1px solid #ccc",
                backgroundColor: "#fff",
                fontSize: "16px",
                fontWeight: "500",
                color: "#333",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                outline: "none",
                cursor: "pointer",
                marginBottom: "12px",
              }}
            >
              <option value="1_month">One Month</option>
              <option value="3_months">Three Months</option>
              <option value="6_months">Six Months</option>
              <option value="annually">Annually</option>
            </select>
          </div>

          <p
            className="partener-form-label"
            style={{
              fontWeight: "bold",
            }}
          >
            Interested in supporting us with your expertise? Please fill in your
            profession.
          </p>
          <div className="partener-form-block">
            <div className="partener-form-input-wrapper">
              <input
                placeholder="E.G Doctor, Nurse ..."
                className="partener-form-input"
                min={50}
                value={skill}
                onChange={(e) => setSkill(e.target.value)}
              />
            </div>
          </div>
        </div>
        {isInvalid && (
          <p className="partener-invalid-input">
            Please Make sure to Enter valid informations..
          </p>
        )}
        <div className="partener-form-submit-wrapper">
          <button
            className="partener-form-submit"
            type="button"
            onClick={handleSubmit}
            disabled={loading}
          >
            <span className="partener-button-text"> Submit </span>
            <PartenerIcon className="partener-button-icon" />
          </button>
        </div>
      </form>
    </div>
  );
}
