const data = {
  email: "holistic@kolfefullgospelchurch.com",
  phone: "0913706570",
  cbe: "1000348098008",
  awash: "10000000555666",
  abyssinia: "100000005555666",
  telebirr: "1000000055556666",
  links: {
    facebook: "https://www.facebook.com",
    twitter: "https://www.twitter.com",
    linkedin: "https://www.linkedin.com",
    telegram: "https://www.telegram.com",
    instagram: "https://www.instagram.com",
  },
  pos: "7171",
  iframe:
    "https://www.youtube.com/embed/sVPYIRF9RCQ?si=jghU9L9VJSavy_zU?autoplay=1",
  development: "https://holistic.kolfefullgospelchurch.com",
  production: "https://holistic.kolfefullgospelchurch.com",
};
export default data;
