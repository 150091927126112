import React from "react";

function Outcome() {
  return (
    <div className="outcome-wrapper">
      <div className="outcome">
        <div className="outcome-card-wrapper">
          <OutcomeCard num="10k" symbol="+" text="fund raised" />
          <OutcomeCard num="5" symbol="+" text="Events" />
        </div>
        <div className="outcome-card-wrapper">
          <OutcomeCard num="50" symbol="" text="Members" />
          <OutcomeCard num="100" symbol="+" text="Volunteers" />
        </div>
      </div>
    </div>
  );
}

export default Outcome;

function OutcomeCard(props) {
  return (
    <div className="outcome-card">
      <h2 className="outcome-card-number oswald">
        {props.num} <span className="outcome-plus">{props?.symbol}</span>
      </h2>
      <p className="outcome-card-quote roboto">{props.text}</p>
    </div>
  );
}
