import React, { useState, useRef } from "react";
import Popup from "../Popup";
import { axiosProtected as axios } from "../../axiosSetup/axiosSetup";
import "./album.css";
import { useNavigate } from "react-router-dom";
import { useProviderContext } from "../provider/adminProvider";
function AddAlbum() {
  const { logoutAdmin } = useProviderContext();
  const navigator = useNavigate();
  const fileInputContainer = useRef();
  const [title, setTitle] = useState("");
  const [isInvalidTitle, setIsInvalidTitle] = useState(false);
  const handleTitleChange = (e) => {
    setTitle(e.target.value);

    if (title.trim().length < 4) {
      setIsInvalidTitle(true);
    } else {
      setIsInvalidTitle(false);
    }
  };

  const [images, setImages] = useState(null);
  const handleImageChange = (e) => {
    setImages(e.target.files);
  };

  const [loading, setLoading] = useState(false);
  const [posted, setPosted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAllInvalid, setIsAllInvalid] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      title.trim().length < 4 ||
      fileInputContainer.current.files.length === 0
    ) {
      return setIsAllInvalid(true);
    }
    setIsAllInvalid(false);
    const imageFormData = new FormData();
    for (let i = 0; i < images.length; i++) {
      imageFormData.append("album", images[i]);
    }
    imageFormData.append("title", title);
    let data = imageFormData;

    try {
      setLoading(true);
      await axios.post("/admin/album", data);
      setLoading(false);
      setPosted(true);
      setTimeout(() => {
        setPosted(false);
      }, 5000);
      setTitle("");
      fileInputContainer.current.value = null;
    } catch (err) {
      if (err?.response?.status === 419) {
        logoutAdmin();
        navigator("/admin/abux-security/login");
        return;
      }
      setLoading(false);
      setError(true);
      setErrorMessage(err.message);
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  };
  return (
    <div className="admin-add-album-wrapper">
      {posted && (
        <Popup
          message={"Album Broadcasted Successfully."}
          type="popup-success"
        />
      )}
      {error && <Popup message={errorMessage} type="popup-error" />}
      <h2 className=" oswald admin-add-album-title">Add Album</h2>
      <div className="add-album-form-block">
        <p className="add-album-form-label">Album Title *</p>
        <div className="add-album-form-input-wrapper">
          <input
            type="text"
            className="add-album-form-input"
            value={title}
            onChange={handleTitleChange}
          />
          {isInvalidTitle && (
            <p className="admin-invalid-input">
              Please fill valid album title.
            </p>
          )}
        </div>
      </div>
      <div className="add-album-form-block">
        <p className="add-album-form-label">Images *</p>
        <div className="add-album-form-input-wrapper">
          <input
            type="file"
            className="add-album-form-input"
            onChange={handleImageChange}
            ref={fileInputContainer}
            multiple
          />
        </div>
      </div>
      {isAllInvalid && (
        <p className="admin-invalid-input">
          Make sure to enter valid informations..
        </p>
      )}
      <div className="add-album-form-block">
        <button
          className={` ${
            loading ? "loading-button" : ""
          } add-album-form-button`}
          type="button"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Loading..." : "Add Album"}
        </button>
      </div>
    </div>
  );
}
export default AddAlbum;
