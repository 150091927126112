import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import SEO from "./components/SEO/SEO";
import Navbar from "./components/navbar/Navbar";

import Welcome1 from "./components/welcome/Welcome1";
import Donate from "./components/donate/Donate";
import Card1 from "./components/card/Card1";
import SupportNow from "./components/supportnow/SupportNow";
import Iframe from "./components/iframe/Iframe";
import Purpose from "./components/purpose/Purpose";
/*all Programs*/
import AllPrograms from "./components/allprograms/AllPrograms";
import FamilyEmpowermentDetail from "./components/allprograms/program/FamilyEmpowerment";
import ChildCareDetail from "./components/allprograms/program/ChildCare";
import CouncilDetail from "./components/allprograms/program/Council";
import BloodDonationDetail from "./components/allprograms/program/BloodDonation";
import SupportHomelessDetail from "./components/allprograms/program/SupportHomeless";
import AdminLogin from "./components/admin/AdminLogin";
import AdminBar from "./components/admin/AdminBar";

//admin Event
import AddEvent from "./components/admin/Event/AddEvent";

//admin blog
import AddBlog from "./components/admin/Blog/AddBlog";
//admin album
import AddAlbum from "./components/admin/Album/AddAlbum";
import Volunteer from "./components/admin/Volunteer/Volunteer";
//admin feedback
import Feedback from "./components/admin/Feedback/Feedback";
import { EachFeedback } from "./components/admin/Feedback/Feedback";

//admin send message
import SendMessage from "./components/admin/Daily-Update/SendDailyUpdate";

//admin admins
import AddAdmin from "./components/admin/Admins/AddAdmin";
import AllAdmins from "./components/admin/Admins/AllAdmins";

//views
import AllAlbum from "./components/admin/Album/AllAlbum";
import AllBlog from "./components/admin/Blog/AllBlog";
import AllEvent from "./components/admin/Event/AllEvent";

import Outcome from "./components/subscribe/Outcome";
/*latest*/
import Gallery, { OneAlbumGallery } from "./components/latest/Gallery";
import Blog from "./components/latest/Blog";
import BlogDetail from "./components/latest/BlogDetail";
import EventPage from "./components/latest/EventPage";
/*About us*/
import Ourhistory from "./components/aboutus/Ourhistory";
import Members from "./components/aboutus/Members";
import Contact from "./components/aboutus/Contact";
import Subscribe from "./components/subscribe/Subscribe";

import Partener from "./components/partener/Partener";
import Footer from "./components/footer/Footer";
import Error from "./components/404/Error";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useProviderContext } from "./components/admin/provider/adminProvider";

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <Routes>
          <Route path="" element={<Enveloper />}>
            <Route
              exact
              path="/"
              element={
                <>
                  <SEO
                    title=""
                    keywords="Holistic Team ,KFGC Holistic,kolfe mulu wengel Holistic Info,Supporting kolfe mulu wengel ,Holistic Services team ,Holistic Services team  ,Holistic team kolfe , Holistic kolfe full gospel ,Holistic service kolfe full gospel detial"
                    description="Kolfe mulu wengel (KFGC) Holistic team Officail Page.Together we can Make Difference Kolfe Full Gospel Holistic Service..."
                  />
                  <Welcome1 />
                  <Card1 />
                  <SupportNow />
                  <Outcome />
                  <Purpose />
                  <Iframe />
                </>
              }
            />

            <Route exact path="/donate" element={<Donate />} />

            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/gallery/:album" element={<OneAlbumGallery />} />

            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog/:id" element={<BlogDetail />} />

            <Route exact path="/events" element={<EventPage />} />

            <Route exact path="/aboutus" element={<Ourhistory />} />
            <Route exact path="/aboutus/members" element={<Members />} />
            <Route exact path="/aboutus/contact" element={<Contact />} />

            <Route exact path="/programs" element={<AllPrograms />} />
            <Route
              exact
              path="/programs/family-empowerment"
              element={<FamilyEmpowermentDetail />}
            />
            <Route
              exact
              path="/programs/child-care"
              element={<ChildCareDetail />}
            />
            <Route
              exact
              path="/programs/counselling"
              element={<CouncilDetail />}
            />
            <Route
              exact
              path="/programs/blood-donation"
              element={<BloodDonationDetail />}
            />
            <Route
              exact
              path="/programs/support-homeless"
              element={<SupportHomelessDetail />}
            />

            <Route exact path="/partener" element={<Partener />} />
          </Route>

          <Route
            exact
            path="/admin/abux-security/login"
            element={<AdminLogin />}
          />

          <Route path="" element={<AdminEnveloper />}>
            <Route exact path="/admin/post/add-event" element={<AddEvent />} />

            <Route exact path="/admin/post/add-blog" element={<AddBlog />} />
            <Route exact path="/admin/post/add-album" element={<AddAlbum />} />

            <Route
              exact
              path="/admin/feedback-message"
              element={<Feedback />}
            />
            <Route
              exact
              path="/admin/feedback-message/:id"
              element={<EachFeedback />}
            />

            <Route
              exact
              path="/admin/post/daily-update"
              element={<SendMessage />}
            />

            <Route exact path="/admin/post/add-admin" element={<AddAdmin />} />

            <Route exact path="/admin/all-admins" element={<AllAdmins />} />

            <Route exact path="/admin/all-albums" element={<AllAlbum />} />
            <Route exact path="/admin/all-blogs" element={<AllBlog />} />
            <Route exact path="/admin/all-events" element={<AllEvent />} />
            <Route exact path="/admin/volunteer-list" element={<Volunteer />} />
          </Route>

          <Route path="*" element={<Error />} />
        </Routes>
      </HelmetProvider>
    </div>
  );
}

export default App;

function Enveloper(props) {
  return (
    <>
      <Navbar />
      <Outlet />
      <Subscribe />
      <Footer />
    </>
  );
}

function AdminEnveloper(props) {
  const {
    state: { admin, token },
  } = useProviderContext();
  console.log(admin, token);

  if (!admin || !token) {
    return <Navigate to="/admin/abux-security/login" replace />;
  }

  return (
    <div className="admin-wrapper">
      <AdminBar admin={admin} />
      <div className="admin-each-component-wrapper">
        <Outlet />
      </div>
    </div>
  );
}
