import React from "react";
import {BlogWelcome} from "./Blog"
import SEO from "../SEO/SEO"
import dateFormat from "dateformat";
import {useParams,useNavigate} from "react-router-dom"
import useFetchClient from "../hooks/useFetchClient"
import { ReactComponent as Calendar } from "../../assets/svg/calendar.svg" 
import Error from "../404/Error"
import Skeleton from "react-loading-skeleton";

function BlogDetail() {
  const navigator = useNavigate();
  let {id} = useParams()
  const {data,error,loading} = useFetchClient("/admin/blog/" + id );
  if(error) {
    return <Error/>
  }
  return(
    <>
    <BlogWelcome/>
    <div className = "blog-detail-wrapper">
    {data &&
       <div className = "blog-detail">
       <SEO 
    title = { "BLOGS - " + data.data[0].title.toUpperCase() }
    keywords = {"Blogs,quote,kolfe mulu wengel Holistic Blogs,kfgc, " + data.data[0].title}
    description = {data.data[0].text}
    />
         <img src = {process.env.PUBLIC_URL + data.data[0].image} alt = "" className = "blog-detail-img" loading = "lazy"/>
         
         <div className = "blog-detail-content roboto">
             <div className = "blog-detail-date">
               <Calendar className = "blog-detail-date-icon"/>           {dateFormat(new Date(data.data[0].date), "ddd, mmm dd, yyyy")}
             </div>
             <h3 className = "blog-detail-title oswald">
                {data.data[0].title}
             </h3>
             <p className = "blog-detail-description">
             {data.data[0].text}
             </p>
           <button type = "button" className = "blog-detail-button" onClick = {() => navigator("/blog")}>
                Back
             </button>
         </div>
       </div>
    }
    
    {loading && 
     <div className = "blog-detail">
        <SkeletonItem/>
      </div>
    }
    </div>
    </>
    )
}

export default BlogDetail;

const SkeletonItem = () => {
  return (
    <section>
      <h2 className="section-title">
        <Skeleton duration={1} height={45} width={350} />
      </h2>
      <h2 className="section-title">
        <Skeleton duration={1} height={20} width={140} />
      </h2>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <li className="card" key={index}>
              <Skeleton height={300} />
            </li>
          ))}
      </ul>
    </section>
  );
};