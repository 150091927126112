import React,{useState} from "react"
import { ReactComponent as Mail } from "../../assets/svg/mail.svg" 
import axios from "../axiosSetup/axiosSetup";
function Subscribe() {
  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [isInvalid,setIsInvalid] = useState(false)
  const [errMessage,setErrMessage] = useState("Please fill valid informations..")
  const [done,setDone] = useState(false)
  
  const handleSubmit = e => {
    e.preventDefault()
    
    if(name.length < 3 || email.length < 5) {
      setErrMessage("Please fill valid informations..")
      return setIsInvalid(true)
    }
    setIsInvalid(false)
    
    axios.post("/admin/daily-update",{name,email})
    .then(res => {
      setDone(true)
      setTimeout(() => setDone(false),2000)
      setIsInvalid(false)
  
     setName("")
    setEmail("")
    })
    .catch(err => {
      setDone(false)
     setIsInvalid(true)
     setTimeout(() => setIsInvalid(false),2000)
      setErrMessage("Failed to process Your Request...")
    })
    

  }
  return(
    <div>
    {/* <h3 className = "subscribe-form-title">
             Subscribe For Daily <span className = "subscribe-itself">Updates</span>
     </h3>
     */}
     
      <div className = "subscribe-wrapper">
      <div className = "subscribe">
         <Mail className = "email-icon"/>
         <h2 className = "be-the-first">
         Be the first to know
         </h2>
         <p className = "be-the-first-description">
          Get exclusive Information Programs,Events, stories and more updates straight to your Inbox.
         </p>
       {isInvalid && <div className = "subscribe-message" >
          {errMessage}  !!
         </div>
        }
       {done && <div className = "subscribe-message" >
          Successfully registered for daily updates. 
         </div>
        }
          <form className = "subscribe-form" onSubmit = {handleSubmit}>
          <label name = "name">
        <input type = "text" className = "subscribe-form-name" placeholder = "First Name" value = {name} onChange = {e => setName(e.target.value)}/>
          </label>
          <label name = "email">
        <input type = "email" className = "subscribe-form-email" placeholder = "Email Address" value = {email} onChange = {e => setEmail(e.target.value)} />
          </label>
          <label name = "submit">
           <input type = "submit" className = "subscribe-form-submit" value = "Subscribe"/>
          </label>
         </form>
       </div>
       </div>
    </div>
    )
}
export default Subscribe; 