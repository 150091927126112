import React from "react"
import { ReactComponent as Mission} from "../../assets/svg/mission.svg"
import { ReactComponent as Vision} from "../../assets/svg/vision.svg"
import { ReactComponent as Goal} from "../../assets/svg/goal.svg";
function Purpose() {
  return (
 <div className = "purpose-wrapper">
    <div className = "purpose-list-wrapper">
       <div className = "purpose-content">
       <MissionCard/>
       <VisionCard/>
       <GoalCard/>
      </div>
    </div>
    </div>
    )
}

export default Purpose;

function MissionCard() {
  return(
        <div className = "purpose-card">
        <Mission className = "purpose-card-icon"/>
        <div className = "purpose-card-content">
               <h3 className = "purpose-card-title oswald">
                Mission
               </h3>
               <p className = "purpose-card-description roboto">
                 Our team is inspired from bible verse james 1 ÷ 27:<br/>
                 Our mission is to give holistic services for our peoples and make them to be independent by psychological, economical, social and spiritual life and able people that benefit to their society,church and country.
               </p>
            </div>
       </div>
    )
}
function VisionCard() {
  return(
        <div className = "purpose-card">
    <Vision className = "purpose-card-icon"/>
            <div className = "purpose-card-content">
               <h3 className = "purpose-card-title oswald">
            Vision
               </h3>
               <p className = "purpose-card-description roboto">
            Our vision is to see all peoples be confident about their economical , psychological, social lifes and be inspired  for other peoples that need help and create moral behaviour about helping peoples and be good every person they meet.
            By Reminding Christians one of our mission in life is to help people in problem and sharing things with them.
               </p>
            </div>
       </div>
    )
}
function GoalCard() {
  return(
        <div className = "purpose-card">
<Goal className = "purpose-card-icon"/>
            <div className = "purpose-card-content">
               <h3 className = "purpose-card-title oswald">
                Goal
               </h3>
               <p className = "purpose-card-description roboto">
                 Our goals are classified in short term and long term:<br/>
                   <b>short term goal </b> we plan to reach disabled peoples, helping students, volunteer in environmental care, Giving character building trainings,blood donations and caring and mentoring childrens.
                 <br/>
                 <b>our long </b> term plan is to help homeless peoples,gammers,disabled peoples, orphan kids in organised and permanent way.
               </p>
            </div>
       </div>
    )
}