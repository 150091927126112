import { createContext, useContext, useReducer } from "react";
import getAdmin from "./getAdmin";

import saveAdmin from "./saveAdmin";
import { saveAccessToken } from "./accessToken";
import { logoutAdminTotally } from "./logoutAdmin";
import { getAccessToken } from "./accessToken";
import { LOG_ADMIN, LOGOUT_ADMIN } from "./actions";

const AppContext = createContext();

// Define initial state
const initialState = {
  admin: getAdmin(),
  token: getAccessToken(),
};
// Define reducer function
const appReducer = (state, action) => {
  switch (action.type) {
    case LOG_ADMIN:
      return {
        ...state,
        admin: action.payload.admin,
        token: action.payload.token,
      };
    case LOGOUT_ADMIN:
      return { ...state, admin: null, token: null };
    default:
      return state;
  }
};

export const AdminCredProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const logAdmin = (admin, accessToken) => {
    saveAdmin(admin);
    saveAccessToken(accessToken);
    dispatch({ type: LOG_ADMIN, payload: { admin, token: accessToken } });
  };
  const logoutAdmin = (admin) => {
    logoutAdminTotally();
    dispatch({ type: LOGOUT_ADMIN });
  };

  return (
    <AppContext.Provider
      value={{
        state,
        logAdmin,
        logoutAdmin,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useProviderContext = () => {
  return useContext(AppContext);
};
