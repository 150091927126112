import React from "react"
import NavbarItem from "./NavbarItem"
import {useNavigate} from "react-router-dom"
import logo from "../../assets/img/logo.jpg"
import SmallScreenNavbar from "./SmallScreenNavbar"
import data from "./data"

function Navbar() {
 const navigator  = useNavigate()
  return (
    <div className = "navbar">
        <div className = "logo-wrapper">
          <img src = {logo} alt = "holistic" className = "logo"/>
          <div className = "logo-text-wrapper">
          <div className = "logo-text ubuntu">Holistic</div>
          </div>
        </div>
        <div className ="menu-items-wrapper">
        {data.map((itm,i) => {
          return (<NavbarItem {...itm} key = {itm.name} />)
        })}
        <button type = "button" className ="navbar-button"  onClick = {() => navigator("/donate")} >
        Donate
        </button>
        </div>
        <div className ="sm-navbar-wrapper">
        <div>
          <button type = "button" className ="sm-navbar-button" onClick = {() => navigator("/donate")} >
        Donate
        </button>
        </div>
        <div>
        <SmallScreenNavbar/>
        </div>
        </div>
    </div>
  );
}

export default Navbar;