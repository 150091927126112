import React,{useState} from "react"
import { ReactComponent as Delete } from "../../../assets/svg/delete.svg"
import Loading from "../Loading"
import FetchError from "../FetchError"
import useFetchClient  from "../../hooks/useFetchClient"
import ConfirmPopup from "../ConfirmPopup"
function AllAlbum() {
  const [isPopedData,setIsPopedData] = useState(null)
const {data,error,loading} = useFetchClient("/admin/album");
if(loading) {
  return <Loading/>
}
if (error) {
  return <FetchError/>
}
if(data) {
   data.data.sort((a,b) => -Number(new Date(a.date)) + Number(new Date(b.date)) )
return (
  <>
    <div className = "admin-view-wrapper">
       <h2 className = "admin-view-title">
          All Albums
       </h2>
      <div className = "admin-view">
      {data.data.map(album => (
      
       <Card img = {album.images[0].path} title = {album.title} key = {album.id} setIsPopedData = {setIsPopedData} id = {album.id} />
      ))}
      </div>
    </div>
      {isPopedData && <ConfirmPopup isPopedData = {isPopedData} setIsPopedData = {setIsPopedData} />}
    </>
    )
}
}

function Card(props) {
  const handledelete = e => {
    props.setIsPopedData({
      type:"album",
      url:"/admin/album",
      id:props.id
    })
  }
  return (
    <div className = "admin-card">
       <div className = "admin-card-img-wrapper">
          <img className = "admin-card-img" src = {process.env.PUBLIC_URL + props.img} loading = "lazy" alt = "" />
       </div>
       <div className = "admin-card-title">
         {props.title.slice(0, props.title.length < 15 ? props.title.length:15)}...
       </div>
       <div className = "admin-card-delete-wrapper">
       <Delete className = "admin-delete-icon" onClick = {handledelete}/>
       </div>
    </div>
    )
}

export default AllAlbum;

