const { ACCESS_TOKEN } = require("../../../global");

export function saveAccessToken(accessToken) {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
}

export function getAccessToken() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  return accessToken;
}

export function removeAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN);
}
