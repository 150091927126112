import React from "react"

function Loading() {
  return(
    <div className = "admin-loading-wrapper">
       <div className = "admin-loading">
          Loading...
       </div>
    </div>
    )
}
export default Loading;