import React from "react";
import useFetch from "../../hooks/useFetch";
import Loading from "../Loading";
import dateFormat from "dateformat";
import FetchError from "../FetchError";
import "./admins.css";

function AllAdmins() {
  const { data, loading, error } = useFetch("/admin/all-registered-admin");
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <FetchError />;
  }
  if (data) {
    return (
      <div className="all-admin-wrapper">
        <h2 className=" oswald all-admin-title">All Active Admins</h2>
        {<AdminBlock admin={data.inChargeAdmin} inCharge />}
        {data.admins.map((admin) => (
          <AdminBlock key={admin.email} admin={admin} inCharge={false} />
        ))}
      </div>
    );
  }
}
export default AllAdmins;

function AdminBlock(props) {
  const date = new Date(props.admin?.createdAt || "");
  return (
    <div className="all-admin-block">
      <p className="all-admin-email">{props.admin.email}</p>
      <div className="all-admin-date">
        {dateFormat(date, "ddd, mmm dd, yyyy")}
      </div>
      {props.inCharge && (
        <div className="all-admin-who-wrapper">
          <div className="all-admin-who"></div>
        </div>
      )}
      {!props.inCharge && (
        <div className="all-admin-others-wrapper">
          <div className="all-admin-others"></div>
        </div>
      )}
    </div>
  );
}
