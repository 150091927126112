import React from "react"
import {useNavigate} from "react-router-dom";
import img from "../../assets/img/welcome2.jpg"
function Welcome1() {
  const navigator = useNavigate()
  return (
   <div className = "home-page">
       <img src = {img} alt = "" className = "home-page-img"/>
       <div className = "home-page-overlay">
       <div className = "home-page-overlay-content">
       <div className = "home-page-title-wrapper">
          <h1 className = "home-page-title oswald">
          Together we can
          <span className = "home-page-title-itself">
          Make Difference 
          </span>
          </h1>
        </div>
        <div className = "home-page-description-wrapper">
          <p className = "home-page-description">
          Kolfe Full Gospel Holistic Service
          </p>
        </div>
          <div className = "home-page-button-wrapper">
             <button className = "home-page-first-button" onClick = {() => navigator("/aboutus/contact")}>
                 Contact Us
             </button>
            <button className = "home-page-second-button" onClick = {() => navigator("/donate")}>
                 Donate Now
             </button>
          </div>
          </div>
       </div>
    </div>
    )
}


export default Welcome1;