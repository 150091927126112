import {useState,useEffect} from "react"
import axios from "../axiosSetup/axiosSetup";

function useFetchClient(url) {
  const [data,setData] = useState(null)
  const [loading,setLoading] = useState(true);
  const [error,setError] = useState(false)
  
  useEffect(() => {
    setLoading(true)
 axios.get(url)
    .then(res => {
        setLoading(false)
        setData(res.data)
    })
    .catch(err => {
      setLoading(false)
      setError(true)
    }) 
   
 
  },[url])
  
  return {data,loading,error}
}
export default useFetchClient;