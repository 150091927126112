import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import ScrollToTop from "./components/scroll/ScrollToTop";
import { AdminCredProvider } from "./components/admin/provider/adminProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <ScrollToTop />
    <AdminCredProvider>
      <App />
    </AdminCredProvider>
  </Router>
);
