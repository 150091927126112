import React,{useState} from "react"
import Popup from "../Popup"
import {axiosProtected as axios} from "../../axiosSetup/axiosSetup";
import "./admins.css"
function AddAdmin() {
const [email,setEmail] = useState("")
const [isInvalidEmail,setIsInvalidEmail] = useState(false);
const handleEmailChange = (e) => {
   setEmail(e.target.value)
  
  if(email.trim().length < 3) {
    setIsInvalidEmail(true)
  }else {
  setIsInvalidEmail(false)
  }
}

const [secret,setSecret] = useState("")
const [isInvalidSecret,setIsInvalidSecret] = useState(false);
const handleSecretChange = (e) => {
   setSecret(e.target.value)
  
  if(secret.trim().length < 3) {
    setIsInvalidSecret(true)
  }else {
  setIsInvalidSecret(false)
  }
}

const [password,setPassword] = useState("")
const [isInvalidPassword,setIsInvalidPassword] = useState(false);
const handlePasswordChange = (e) => {
   setPassword(e.target.value)
  
  if(password.length < 6) {
    setIsInvalidPassword(true)
  }else {
  setIsInvalidPassword(false)
  }
}

const [confirmPassword,setConfirmPassword] = useState("")
const [isInvalidConfirmPassword,setIsInvalidConfirmPassword] = useState(false);
const handleConfirmPasswordChange = (e) => {
   setConfirmPassword(state => e.target.value)
  if(e.target.value !==  password) {
    setIsInvalidConfirmPassword(true)
  } else {
  setIsInvalidConfirmPassword(false)
  }
}

const [loading,setLoading] = useState(false)
const [posted,setPosted] = useState(false)
const [error,setError] = useState(false)
const [errorMessage,setErrorMessage] = useState("");
const [isAllInvalid,setIsAllInvalid] = useState(false)
const handleSubmit = async (e) => {
     e.preventDefault()
  if(email.trim().length < 3 || secret.trim().length < 3 || password.length < 6 || confirmPassword !==  password){
   return setIsAllInvalid(true)
  }
  setIsAllInvalid(false)
  

const data = {
  email,
  secret,
  password
}
  
  try {
setLoading(true)
await axios.post("/admin/add-new-admin",data);
setLoading(false)
setPosted(true)
setTimeout(() => {
    setPosted(false)
  },5000)
setEmail("")
setPassword("")
setSecret("")
setConfirmPassword("")
}catch (err) {
  setLoading(false)
  setError(true)
  setErrorMessage(err.message)
  setTimeout(() => {
    setError(false)
  },5000)
}
}
  return (
    <div className = "admin-add-admin-wrapper">
       {posted && <Popup message = {"Admin Created Successfully.."} type = "popup-success" />}
    {error && <Popup message = {errorMessage} type = "popup-error" />}
       <h2 className = " oswald admin-add-admin-title">
          Create New Admin...
       </h2>
       <div className = "add-admin-form-block">
       <p className = "add-admin-form-label">
         Email *
       </p>
       <div className = "add-admin-form-input-wrapper">
       <input type = "email" className = "add-admin-form-input"  value = {email} onChange = {handleEmailChange} required/>
      {isInvalidEmail && <p className = "admin-invalid-input">
          Please fill valid email...
       </p>}
       </div>
       </div>
           <div className = "add-admin-form-block">
       <p className = "add-admin-form-label">
        Secret Word *
       </p>
       <div className = "add-admin-form-input-wrapper">
       <input type = "text" className = "add-admin-form-input"  value = {secret} onChange = {handleSecretChange} required/>
      {isInvalidSecret && <p className = "admin-invalid-input">
          Please fill valid secret word...
       </p>}
       </div>
       </div>
    <div className = "add-admin-form-block">
       <p className = "add-admin-form-label">
         Password *
       </p>
       <div className = "add-admin-form-input-wrapper">
       <input type = "password" className = "add-admin-form-input" value = {password} onChange = {handlePasswordChange}/>
      {isInvalidPassword && <p className = "admin-invalid-input">
          password must contain 6 characters...
       </p>}
       </div>
       </div>
    <div className = "add-admin-form-block">
       <p className = "add-admin-form-label">
        Confirm Password *
       </p>
       <div className = "add-admin-form-input-wrapper">
       <input type = "password" className = "add-admin-form-input"  value = {confirmPassword} onChange = {handleConfirmPasswordChange}/>
      {isInvalidConfirmPassword && <p className = "admin-invalid-input">
          passwords are not same...
       </p>}
       </div>
       </div>
    {isAllInvalid && 
         <p className = "admin-invalid-input">
         Make sure to enter valid admin informations..
         </p>
       }
    <div className = "add-admin-form-block">
        <button className = {` ${loading ? "loading-button" : ""} add-admin-form-button`} onClick = {handleSubmit}  type = "button" disabled = {loading}>
        {loading ? "Loading..." : "Add Admin"}
      </button>
    </div>
    
 </div>
    )
}
export default AddAdmin;