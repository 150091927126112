import React from "react";

function FetchError() {
  return (
    <div className="admin-fetch-error-wrapper">
      <div className="admin-fetch-error">
        Unable to Fetch Data ... <br />
        Please re-login again.
      </div>
    </div>
  );
}
export default FetchError;
