import React from "react"
import useFetch from "../../hooks/useFetch"
import Loading from "../Loading"
import FetchError from "../FetchError"
import "./volunteer.css"
function Volunteer() {
const {data:resp,loading,error} = useFetch("/admin/volunteer");
 if(loading) {
   return <Loading/>
 }
 if (error) {
   return <FetchError/>
 }
 
 if(resp) {
if(resp.data) {
   resp.data.sort((a,b) => -Number(new Date(a.date)) + Number(new Date(b.date)) )
  }
  return (
    <div className = "admin-volunteer-wrapper">
       <h2 className = " oswald admin-volunteer-title">
        Registered Volunteers
       </h2>
       {resp.data.length ? resp.data.map(data => (
       <MessageBlock key = {data.id} data = {data}/>
       )) : <div className = "no-volunteer-yet">"No Registered Volunteer yet..."</div>}
    
 </div>
    )
 }
}
export default Volunteer;

function MessageBlock(props) {
return (
     <div className ="volunteer-block">
     <div className = "volunteer-block-table">
         <p className = "volunteer-label">
           NAME : {props.data.name.toUpperCase()}
         </p>
       <div className = "volunteer-message">
      EMAIL :   {props.data.email.toUpperCase()}
       </div>
       </div>
         <div className = "volunteer-block-table">
         <p className = "volunteer-label">
            PHONE NO:
         </p>
       <div className = "volunteer-message">
        {props.data.phoneNo}
       </div>
       </div>
      </div>
    )
}

