import React from "react";
import {Link} from "react-router-dom"
function Dropdown(props) {
  
  return ( 
    <> 
       {props.data.map((itm,i) => {
         return (
      <React.Fragment key = {itm.name}>
     <Link to = {itm.link} className = "drop-down-link">
       {itm.icon}
       <div className ="drop-down-link-text">{itm.name}</div> 
    </Link>
    </React.Fragment>
         )
       })}
    </>
    )
}

export default Dropdown;