import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import SEO from "../SEO/SEO";
import logo from "../../assets/img/logo.jpg";
import axios from "../axiosSetup/axiosSetup";
import "./admin.css";
import { useProviderContext } from "./provider/adminProvider";

function AdminLogin() {
  const {
    logAdmin,
    state: { admin },
  } = useProviderContext();

  const navigator = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [incorrectCredentials, setIncorrectCredentials] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Please Use Valid credentials!!"
  );
  const [invalid, setInvalid] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.length < 6 || !email.includes("@") || password.length < 6) {
      return setInvalid(true);
    }
    setInvalid(false);
    const credentials = { email: email.trim(), password };
    try {
      setLoading(true);
      const resp = await axios.post("/admin/login", credentials);

      setIncorrectCredentials(false);
      const token = resp.data.token;
      const admin = resp.data.admin;

      if (!token || !admin) {
        setLoading(false);
        return setIncorrectCredentials(true);
      }
      console.log(admin, token);
      logAdmin(admin, token);
      setLoading(false);
      navigator("/admin/post/add-event");
    } catch (err) {
      console.log(err);
      setLoading(false);
      setIncorrectCredentials(true);

      if (err?.response?.status === 429) {
        setErrorMessage(
          err?.response?.data?.message ||
            "To Many Requests Please Try Again Later!"
        );
      } else {
        setErrorMessage(
          err?.response?.data?.message || "Please Use Valid credentials!"
        );
      }
    }
  };

  if (admin) {
    return <Navigate to="/admin/post/add-event" replace />;
  }

  return (
    <div className="admin-login-wrapper">
      <SEO title="ADMIN" keywords="Holistic Admin Control" />
      <div className="admin-login">
        <div className="admin-login-topic">
          <img src={logo} className="admin-logo" alt="" />
          <h3 className="roboto admin-login-title">Holistic Admin</h3>
        </div>
        <div className="admin-login-form">
          <div className="admin-form-block">
            {incorrectCredentials && (
              <p className="incorrect-credentials">{errorMessage}</p>
            )}
          </div>
          <form>
            <div className="admin-form-block">
              <p className="ubuntu admin-form-label">UserName</p>
              <input
                type="text"
                className="admin-form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="admin-form-block">
              <p className="ubuntu admin-form-label">Password</p>
              <input
                type="password"
                className="admin-form-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="admin-form-block">
              {invalid && (
                <p className="incorrect-credentials">
                  Please Fill valid Information...{" "}
                </p>
              )}
            </div>
            <div className="admin-form-block">
              <button
                className="admin-form-button roboto"
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Finding..." : "Login"}
              </button>
            </div>
          </form>

          <div className="admin-form-block">
            <p className="admin-form-quote">
              {/*<Link  to = "/admin/forget-password/hashed" className = "forget-link">Forget Password? </Link>*/}
            </p>
          </div>
          <div className="admin-form-block">
            <p className="admin-form-quote">
              &copy; Holistic team developed by{" "}
              <a href="https://t.me/blackcode22" className="abux-link">
                Abux
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
