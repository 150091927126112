import React, { useState, useRef } from "react";
import Popup from "../Popup";
import { axiosProtected as axios } from "../../axiosSetup/axiosSetup";
import "./event.css";
import { useProviderContext } from "../provider/adminProvider";
import { useNavigate } from "react-router-dom";
function AddEvent() {
  const { logoutAdmin } = useProviderContext();
  const navigator = useNavigate();
  const fileInputContainer = useRef();
  const [title, setTitle] = useState("");
  const [isInvalidTitle, setIsInvalidTitle] = useState(false);
  const handleTitleChange = (e) => {
    setTitle(e.target.value);

    if (title.trim().length < 4) {
      setIsInvalidTitle(true);
    } else {
      setIsInvalidTitle(false);
    }
  };
  const [quote, setQuote] = useState("");
  const [isInvalidQuote, setIsInvalidQuote] = useState(false);
  const handleQuoteChange = (e) => {
    setQuote(e.target.value);

    if (quote.trim().length < 3) {
      setIsInvalidQuote(true);
    } else {
      setIsInvalidQuote(false);
    }
  };
  const [image, setImage] = useState(null);
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const [text, setText] = useState("");
  const [isInvalidText, setIsInvalidText] = useState(false);
  const handleTextChange = (e) => {
    setText(e.target.value);

    if (text.trim().length < 10) {
      setIsInvalidText(true);
    } else {
      setIsInvalidText(false);
    }
  };

  const [fb, setFb] = useState("");
  const [tw, setTw] = useState("");
  const [insta, setInsta] = useState("");
  const [li, setLi] = useState("");

  //handleSubmit
  const [loading, setLoading] = useState(false);
  const [posted, setPosted] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAllInvalid, setIsAllInvalid] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      title.trim().length < 4 ||
      quote.trim().length < 3 ||
      text.trim().length < 10 ||
      fileInputContainer.current.files.length === 0
    ) {
      return setIsAllInvalid(true);
    }
    setIsAllInvalid(false);
    const imageFormData = new FormData();
    imageFormData.append("event-img", image);
    imageFormData.append("title", title);
    imageFormData.append("quote", quote);
    imageFormData.append("text", text);
    const linksArray = [
      { facebook: fb, twitter: tw, instagram: insta, linkedin: li },
    ];
    linksArray.forEach((links) => {
      imageFormData.append("links[]", JSON.stringify(links));
    });
    let data = imageFormData;
    try {
      setLoading(true);
      await axios.post("/admin/event", data);

      setLoading(false);
      setPosted(true);
      setTimeout(() => {
        setPosted(false);
      }, 5000);
      setTitle("");
      setQuote("");
      setText("");
      setFb("");
      setTw("");
      setInsta("");
      setLi("");
      fileInputContainer.current.value = null;
    } catch (err) {
      if (err?.response?.status === 419) {
        logoutAdmin();
        navigator("/admin/abux-security/login");
        return;
      }

      setLoading(false);
      setError(true);
      setErrorMessage(err.message);
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  };

  return (
    <div className="admin-add-event-wrapper">
      {posted && (
        <Popup message={"Event Broadcast Successfully."} type="popup-success" />
      )}
      {error && <Popup message={errorMessage} type="popup-error" />}
      <h2 className=" oswald admin-add-event-title">Broadcast Event</h2>
      <div className="add-event-form-block">
        <p className="add-event-form-label">Title *</p>
        <div className="add-event-form-input-wrapper">
          <input
            type="text"
            className="add-event-form-input"
            value={title}
            onChange={handleTitleChange}
          />
          {isInvalidTitle && (
            <p className="admin-invalid-input">Please fill valid title.</p>
          )}
        </div>
      </div>
      <div className="add-event-form-block">
        <p className="add-event-form-label">Quote *</p>
        <div className="add-event-form-input-wrapper">
          <input
            type="text"
            className="add-event-form-input"
            value={quote}
            onChange={handleQuoteChange}
          />
          {isInvalidQuote && (
            <p className="admin-invalid-input">please put quote</p>
          )}
        </div>
      </div>
      <div className="add-event-form-block">
        <p className="add-event-form-label">Image *</p>
        <div className="add-event-form-input-wrapper">
          <input
            type="file"
            className="add-event-form-input"
            onChange={handleImageChange}
            ref={fileInputContainer}
          />
        </div>
      </div>
      <div className="add-event-form-block">
        <p className="add-event-form-label">Text *</p>
        <div className="add-event-form-textarea-wrapper">
          <textarea
            className="add-event-form-textarea roboto"
            placeholder="Event text..."
            value={text}
            onChange={handleTextChange}
          ></textarea>
          {isInvalidText && (
            <p className="admin-invalid-input">please put message...</p>
          )}
        </div>
      </div>
      <div className="add-event-form-block">
        <p className="add-event-form-label">Facebook link</p>
        <div className="add-event-form-input-wrapper">
          <input
            type="url"
            className="add-event-form-input"
            placeholder="Facebook link..."
            value={fb}
            onChange={(e) => setFb(e.target.value)}
          />
        </div>
      </div>
      <div className="add-event-form-block">
        <p className="add-event-form-label">Twitter link</p>
        <div className="add-event-form-input-wrapper">
          <input
            type="text"
            className="add-event-form-input"
            placeholder="Twitter link..."
            value={tw}
            onChange={(e) => setTw(e.target.value)}
          />
        </div>
      </div>
      <div className="add-event-form-block">
        <p className="add-event-form-label">Instagram link</p>
        <div className="add-event-form-input-wrapper">
          <input
            type="text"
            className="add-event-form-input"
            placeholder="Instagram link..."
            value={insta}
            onChange={(e) => setInsta(e.target.value)}
          />
        </div>
      </div>
      <div className="add-event-form-block">
        <p className="add-event-form-label">Linked link</p>
        <div className="add-event-form-input-wrapper">
          <input
            type="text"
            className="add-event-form-input"
            placeholder="Linkedin  link..."
            value={li}
            onChange={(e) => setLi(e.target.value)}
          />
        </div>
      </div>
      {isAllInvalid && (
        <p className="admin-invalid-input">
          Make sure to enter valid informations and image..
        </p>
      )}
      <div className="add-event-form-block">
        <button
          className={` ${
            loading ? "loading-button" : ""
          } add-event-form-button`}
          onClick={handleSubmit}
          disabled={loading}
          type="button"
        >
          {loading ? "Loading..." : "Broadcast Event"}
        </button>
      </div>
    </div>
  );
}
export default AddEvent;
