import {axiosProtected as axios} from "../axiosSetup/axiosSetup";
function ConfirmPopup(props) {
  const handleCancel = e => {
    props.setIsPopedData(null)
  }
  const handleOk = async  e => {
    try {
    await axios.delete(props.isPopedData.url + "/" + props.isPopedData.id)
     window.location.reload()
    }
    catch(err) {
    window.location.reload()
  }
  }
  
  return ( 
    <div className = "admin-confirm-popup-wrapper">
       <div className = "admin-confirm-popup">
          <div className = "admin-confirm-popup-message">
            Confirm Your Action?
          </div>
          <div className = "admin-confirm-popup-message">
            Are You Sure You want to delete this
          </div>
          
          <div className = "admin-confirm-button-wrapper">
          <button className = "admin-confirm-button  admin-confirm-button-ok" onClick = {handleOk}>
            Ok
          </button>
        <button className = "admin-confirm-button" onClick = {handleCancel}>
            Cancel
          </button>
          </div>
       </div>
    </div>
    
    )
}

export default ConfirmPopup;