import React from "react"
import SEO from "../SEO/SEO"
import {Link} from "react-router-dom"
import img from "../../assets/img/pages/all-program.jpg"
import actnow from "../../assets/img/allprograms/actnow.jpg"
import {useNavigate} from "react-router-dom"

import { ReactComponent as Programs} from "../../assets/svg/settings.svg" 
import { ReactComponent as Children} from "../../assets/svg/children.svg" 
import { ReactComponent as Family} from "../../assets/svg/family.svg" 
import { ReactComponent as Council} from "../../assets/svg/council.svg" 
import { ReactComponent as Homeless} from "../../assets/svg/homeless.svg" 
import { ReactComponent as Blood} from "../../assets/svg/medkit.svg" 
import { ReactComponent as Proffession} from "../../assets/svg/proffession.svg" 
import { ReactComponent as Donate} from "../../assets/svg/donate.svg" 
import "./Allprogram.css"
function AllPrograms() {
  return (
 <>
    <SEO 
    title = "OUR PROGRAMS"
    keywords = "Programs,KFGC Holistic programs,kolfe mulu wengel Holistic programs,Supporting kolfe mulu wengel,Holistic Services"
    description = "Kolfe mulu wengel (KFGC) Holistic team Programs and services to support the ones in needs... "
    />
    <AllProgramWelcome/>
  <div className ="all-program-wrapper">
   <div className = "all-program">
    <h3 className = "all-program-big-title oswald">
      way we Help
     </h3>
   <div className = "all-program-card-wrapper">
     <AllProgramCard 
     icon = {<Family className = "oswald all-program-card-icon"/>} 
     title = "Family Empowerment"
     text = "Empowering Family by supporting in many aspects economical (basic needs), psychological ,counselling ..."
     link = "/programs/family-empowerment"
     />
     <AllProgramCard 
     icon = {<Children className = "oswald all-program-card-icon"/>} 
     title = "Child Care"
     text = "Caring children visiting orpahan age, guiding,mentorship,experience sharing and facilitate other training events..."
     link = "/programs/child-care"
     />
     <AllProgramCard 
     icon = {<Council className = "oswald all-program-card-icon"/>} 
     title = "Counselling and Training"
     text = "Our team prepare blood donation per 3 months permantly ... "
    link = "/programs/counselling"
     />
      <AllProgramCard 
     icon = {<Blood className = "oswald all-program-card-icon"/>} 
     title = "Blood Donation"
     text = "Our team prepare blood donation per 3 months permantly ... "
    link = "/programs/blood-donation"
     />
   <AllProgramCard 
     icon = {<Homeless className = "oswald all-program-card-icon"/>} 
     title = "Support homeless peoples"
     text = "Our team prepare blood donation per 3 months permantly ... "
    link = "/programs/support-homeless"
     />
  </div>
 </div>
 <AllProgramFullImage/>
 <div className = "all-program">
    <h3 className = "all-program-big-title oswald">
      Get Involved
   </h3>
   <div className = "all-program-card-wrapper">
       <AllProgramCard 
     icon = {<Proffession className = "oswald all-program-card-icon"/>} 
     title = "Be Volunteer"
     text = "You can support us by everything you want. Be partener and work with us by your Knowledge,Proffession,Time...."
     linkText = "Be Partener"
     link = "/partener"
     />
     <AllProgramCard 
     icon = {<Donate className = "oswald all-program-card-icon"/>} 
     title = "Support By Donation"
     text = "Support us By your Donation  Donate Now..."
     linkText = "Donate Now"
     link = "/donate"
     />
  </div>
   </div>
  </div>
   </>
    )
}
export default AllPrograms;

function AllProgramWelcome() {
  return (
    <div className = "all-program-welcome">
       <img src = {img} className = "all-program-welcome-img" alt = "" loading = "lazy" />
     <div className = "all-program-welcome-content-wrapper">
       <div className = "all-program-welcome-content">
       <div className = "all-program-welcome-title-wrapper">
           <div className = "all-program-welcome-icon-wrapper">
           <Programs className = "all-program-welcome-icon"/>
           </div>
          <h2 className = "all-program-welcome-title oswald">
               All Our Programs
          </h2>
          </div>
          <div className = "all-program-welcome-text-wrapper">
          <p className = "all-program-welcome-text">
            Do small things with great passion.
          </p>
          </div>
       </div>
       </div>
    </div>
    )
}

function AllProgramCard(props) {
  return (
    <div className = "all-program-card">
       <div className = "all-program-card-icon-wrapper">
        {props.icon}
       </div>
         <h2 className = "all-program-card-title oswald">
         {props.title} 
         </h2>
       <div className = "all-program-card-description-wrapper">
       <div className = "all-program-card-description roboto">
         {props.text}
      </div>
      </div>
      <div className = "all-program-card-link-wrapper">
       <Link className = "all-program-card-link oswald" to = {props.link}>
       {props.linkText ? props.linkText:"Read more"}
       </Link>
    </div>
    </div>
    )
}

function AllProgramFullImage() {
const navigator  = useNavigate()
return(
    <div className = "all-program-full-img-wrapper">
    <img src = {actnow} alt = "" className = "all-program-full-img" loading = "lazy"/>
    <div className = "all-program-full-img-overlay">
       <div className = "all-program-full-img-overlay-content">
       <h3 className = "all-program-full-img-overlay-title">
       Help One Now
       </h3>
       <div className = "all-program-full-img-overlay-description">
         Help people's in need for help...
       </div>
       <button className = "all-program-full-img-overlay-button" onClick = {() => navigator("/donate")}>
          Donate Now
       </button>
       </div>
    </div>
    </div>
    )
}