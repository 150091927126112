import React from "react"
import SEO from "../../SEO/SEO"
import council from "../../../assets/img/programs/council/council.jpg"
import "./program.css"

function CouncilDetail() {
  return (
    <>
       <SEO 
    title = "PROGRAMS - Conciling"
    keywords = "Programs,KFGC Holistic programs,kolfe mulu wengel Holistic programs,Supporting kolfe mulu wengel,Holistic Services,Holistic Counciling,Kfgc Concil"
    description = "Kolfe mulu wengel (KFGC) Holistic team Programs and services to support,council depends on... "
    />
    <ProgramDetailContent/>
    </>
    )
}
export default CouncilDetail;

function ProgramDetailContent() {
  return (
   <div className = "one-program-detail-wrapper">
      <div className = "one-program-detail">
      <ProgramDetailHeader/>
      <ProgramDetailDefination/>
      <OneProgramDetailMiddleDefination/>
      </div>
  </div>
    )
}
function ProgramDetailHeader() {
  return (
    <div className = "one-program-detail-topic">
       <div className = "one-program-detail-topic-breadcrumbs">
       <span className = "one-program-breadcrumbs-title">Our Programs</span>
       <span className = "one-program-breadcrumbs-dot">●</span>
      <span className = "one-program-breadcrumbs-title">Programs</span>
      <span className = "one-program-breadcrumbs-dot">●</span>
     <span className = "one-program-breadcrumbs-title">counselling</span>
       </div>
       <h3 className = "one-program-title">
    Counselling and Training 
       </h3>
    </div>
    )
}
function ProgramDetailDefination() {
  return (
    <div className = "one-program-description-wrapper">
       <div className = "one-program-description roboto">
       
          <div className = "one-program-detail-paragraph">
Kolfe Mulu Wengel Holistic Service is a volunteer organization committed to providing counseling services without discrimination based on age, sex, race, or any other factor. The organization recognizes the importance of psychological support for individuals experiencing crises in their lives. Their trained counselors offer compassionate guidance and therapeutic interventions to help people navigate through challenging situations and find solutions to their problems.
       </div>
   <div className = "one-program-detail-paragraph">

In addition to crisis counseling, Kolfe Mulu Wengel Holistic Service focuses on guiding teenagers and young people, providing them with valuable insights into their social and spiritual lives. Through mentorship programs and one-on-one counseling sessions, the organization helps young individuals make informed decisions, develop healthy relationships, and explore their spiritual journey. By addressing their emotional and spiritual needs, the organization empowers and supports young people in their personal growth and development.
       </div>
       </div>
       <div className = "one-program-description-img-wrapper">
       <img src = {council} className = "one-program-description-img" alt = ""/>
       </div>
    </div>
    )
}

function OneProgramDetailMiddleDefination() {
  return (
    <div className = "one-program-middle-defination-wrapper">
   <div className = "one-program-middle-description">
   <div className = "one-program-detail-paragraph">
  Furthermore, Kolfe Mulu Wengel Holistic Service facilitates various programs aimed at sharing knowledge and fostering personal growth. They organize panel discussions, inviting experts, life teachers, and motivational speakers to inspire and educate individuals. These programs provide a platform for learning, self-reflection, and personal transformation, helping individuals gain new perspectives and tools to overcome challenges and improve their well-being.
       </div>
   <div className = "one-program-detail-paragraph">
     In summary, Kolfe Mulu Wengel Holistic Service is a compassionate volunteer organization that offers counseling services to individuals regardless of their age, sex, race, or background. By providing psychological treatment during crises, guiding teenagers and young people, and facilitating programs for knowledge sharing, the organization promotes personal growth, emotional well-being, and spiritual development. Their commitment to supporting individuals in their life journeys demonstrates their dedication to fostering a holistic approach to counseling and empowerment.
     </div>
  </div>
    </div>
    )
}