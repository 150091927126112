import React from "react";
import SEO from "../SEO/SEO"
import img from "../../assets/img/pages/about_us.jpg"
import programImg from "../../assets/img/aboutus/program.jpg"
import {Link,useNavigate} from "react-router-dom"
import logo from "../../assets/img/logo.jpg"
import "./Aboutus.css";
function Ourhistory() {
  return (
    <>
       <SEO 
    title = "OUR HISTORY"
    keywords = "History,Foundation,KFGC Holistic Foundation,kolfe mulu wengel Holistic history,Supporting kolfe mulu wengel team who are we ,Holistic Services team Foundation"
    description = "Kolfe mulu wengel (KFGC) Holistic team foundation,history amd accomplishment..."
    />
      <OurhistoryWelcome/>
      <div className = "our-history-wrapper">
         <div className = "our-history">
            <OurhistoryHeader/>
            <OurhistoryQuote/>
           <OurhistoryRealNote/>
         </div>
      </div>
   </>
    )
}
export default Ourhistory;


function OurhistoryWelcome() {
const navigator  = useNavigate()
return(
     <div className = "member-welcome">
       <img src = {img} className = "member-welcome-img" alt = "" loading = "lazy" />
     <div className = "member-welcome-content-wrapper">
       <div className = "member-welcome-content">
         <div className = "member-welcome-title-wrapper">
          <h2 className = "member-welcome-title oswald">
             About Us
          </h2>
          </div>
          <div className = "member-welcome-text-wrapper">
          <div className = "member-welcome-text">
            This is description about team
          </div>
          </div>
          <div className = "member-welcome-button-wrapper">
          <button className = "member-welcome-button roboto" type = "button" onClick = {() => navigator("/partener")}>
            Be Volunteer
          </button>
          </div>
       </div>
       </div>
    </div>
    )
}


function OurhistoryHeader() {
  return (
    <div className = "one-program-detail-topic">
       <div className = "one-program-detail-topic-breadcrumbs">
       <span className = "one-program-breadcrumbs-title">About</span>
       <span className = "one-program-breadcrumbs-dot">●</span>
      <span className = "one-program-breadcrumbs-title">Who are We</span>
       </div>
       <h3 className = "one-program-title">
          Who are We
       </h3>
    </div>
    )
}

function OurhistoryQuote() {
  return (
    <div className = "our-history-quote-wrapper">
      <div className = "our-history-quote" >
    <div className = "our-history-quote-paragraph">
     Kolfe full gospel Holistic service  team is  create in August 2020 (Aprill 2012)  and serving our community by many aspects. Our team is inspired by from Bible verse :
 <div className = "our-history-quote-paragraph bible-quote">
“Pure religion and undefiled before God and the Father is this, To visit the fatherless and widows in their affliction, and to keep himself unspotted from the world.”
  — James 1፥27 "<br/>
Thinking about people in trouble is pure and undefiled reverence and God is grateful to accept it.so our team is inspired and start working in seven groups
</div>
</div>
  <div className = "our-history-quote-paragraph">
  Holistic service Team is give Holistic services and try to create an independent individual in there economical,psychological and spiritual  aspects and try to see individual lifes health spiritual life.
</div>
 <div className = "our-history-quote-paragraph">
Our focus is to reach people in church and in society that can't fulfil there basic need s (food , cloth and shelter) and support them by facilitate events , fundraising.
</div>
      </div>
      <div className = "our-history-quote-img-wrapper">
      <img src = {logo} className = "our-history-quote-img" alt = "" />
      </div>
    </div>
    )
}

/*
function OurhistoryTopDefination() {
  return (
    <div className = "our-history-content-wrapper">
    <div className = "our-history-content">
     <div className = "our-history-content-paragraph">
        The property is useful since the legibility of fonts, especially at small sizes, is determined more by the size of lowercase letters than by the size of capital letters. Legibility can become an issue when the first-choice font-family is unavailable and its replacement has a significantly different aspect ratio (the ratio of the size of lowercase letters to the size of the font).
        The property is useful since the legibility of fonts, especially at small sizes, is determined more by the size of lowercase letters than by the size of capital letters. Legibility can become an issue when the first-choice font-family is unavailable and its replacement has a significantly different aspect ratio (the ratio of the size of lowercase letters to the size of the font).
         <div className ="our-history-button-holder">
          <button className ="our-history-button our-history-readmore-button">
             Read More
          </button>
            <button className ="our-history-button our-history-document-button">
             View Report 
          </button>
          </div>
          
        </div>
    <div className = "our-history-content-img-wrapper">
    <img src = {img} className = "our-history-content-img" alt = "" />
    </div>
      </div>
    </div>
    )
}
*/


function OurhistoryRealNote() {
  return (
      <div className = "our-history-story-wrapper">
     <div className = "our-history-story">
        <h1 className = "our-history-story-title">
            What is Holistic mean?
        </h1>
         <NoteBullet text = {"Spiritual"} />
        <div className = "our-history-quote-paragraph">
      Encouraging individuals and facilitate ways to improve Relation with God by praying and reading bible permanently .. <Link to = "/programs/counselling">counselling</Link>
        </div>
      <NoteBullet text = {"Financially"} />
        <div className ="our-history-quote-paragraph">
       Supporting family in economical by fund or material in Accidents,Health,House rent...  .. <Link to = "/programs/family-empowerment">family empowerment</Link>
       <div className = "bible-quote">
   “Neither was there any among them that lacked: for as many as were possessors of lands or houses sold them, and brought the prices of the things that were sold,”
  — Acts 4፥34 
    </div>
    </div>
    <NoteBullet text = {"Socially"} />
        <div className ="our-history-quote-paragraph">
       Creating good social life with community and have good communications with people's by creating chance to communicate,events and other programs.
       <div className = "bible-quote">
 “If it be possible, as much as lieth in you, live peaceably with all men.”
  — Romans 12፥18 
    </div>
    </div>
   <NoteBullet text = {"Psychologically"} />
        <div className ="our-history-quote-paragraph">
     By Appreciate Having positive affirmation about our selves,our things and our communities by facilitating events,training,experience sharing and by counselling individual and family
    </div>
    <img src = {programImg} alt = "" className = "our-history-full-img"/>
       <h1 className = "our-history-story-title">
         How We Help?
        </h1>
  
         <div className = "our-history-story-paragraph">
         <h3 className ="our-history-story-title">
            Human Resource Management 
         </h3>
          》human resource is one of the big advantage to help people and we use our human resource to do some volunteer activities like visiting orphange, visiting disabled peoples ,to create communicable community and for fundraising process.
        </div>
        
       <div className = "our-history-story-paragraph">
         <h3 className ="our-history-story-title">
            Financial Resource Management 
         </h3>
          》 Ways to mange our finacial resource :
          <div className = "aboutus-subtitle">How many Fund do we have?</div>
        》 we are manage fund resource by supporting critical problems and try to reach critical crisis in our community.
         <div className = "aboutus-subtitle">How we fundraise?</div>
         》 we are try to reach people around world to raise and help others by facilitating events and online supporting system.
        <div className = "aboutus-subtitle" >How we support people in need?</div>
        》 our team try to identify that need help in finacial resource by inspecting Community.
        </div>
          <div className = "our-history-story-paragraph">
         <h3 className ="our-history-story-title">
          Material Resource Management 
         </h3>
         》 Our team prepare programs, events to collect materials from their members volunteers and other people in events that can be used for other people it can be fund, clothes, shoes and outher material and responsibly spread for the people's in need for the material and support them.
         </div>
        
          <div className = "our-history-story-paragraph">
         <h3 className ="our-history-story-title">
          Time Resource Management 
         </h3>
         》 Our teams use Time as major way to help people :
     <h5 className ="our-history-story-title">Mentorship</h5>
         》 Holistic service team facilitate events programs especially for young about there future life, school ,experience sharing, mentoring, guiding by other entrepreneurs and visionery people by creating communicating moments for our community and members.
    <h5 className ="our-history-story-title">Volunteering</h5>
         》 Holistic service team volunteer in development activity , preserving environment activity fundraising activity our members and volunteers  are volunteer in may activities.
     <h5 className ="our-history-story-title">Visiting</h5>
         》 our team used to visit people that disable or people with some Health problem visiting people and investing time with the is one way of building psychological strength for people and there are many these type of people in home hospital need this type of volunteerism  to make them hope for future and even to be happy.
         <div>
         》 visiting children in orphanage is one of our work in time management visiting children is way to create responsible generation about social thinking and building hope of childern create good communicable young for society.
         </div>
         </div>
     <div className = "our-history-story-paragraph">
         <h3 className ="our-history-story-title">
          Proffession and knowledge Management 
         </h3>
  <NoteBullet text = {"Proffession volunteering"} />
         》 Holistic services team is  constructed by many members and volunteers in many diverse Proffession these peoples are volunteers in there Proffession skills 
         like by mentoring, health care,by building,by working...in many ways these Proffession diversity is major way to help other people's in need for our service.
         
    <NoteBullet text = {"knowledge share training"} />
         》 Holistic services team is facilitate events programs to share knowledge from visionery people and create communicable  society for knowledge sharing to members.
    </div>
      <div className = "our-history-story-paragraph">
         <h3 className ="our-history-story-title">
          Our sub team Management 
         </h3>
        <NoteBullet text = {"Counselling Team "} />
      <NoteBullet text = {"Family/Marriage Counselling Team "} />
       <NoteBullet text = {"Medical Team "} />
     <NoteBullet text = {"Child development Team "} />
     <NoteBullet text = {"Mentorship Team "} />
      </div>
            </div>
            
            </div>
    )
}

function NoteBullet(props) {
  return(
    <div className = "our-history-story-bullet-note">
  <span className = "our-history-story-bullet" > 》</span> {props.text}
    </div>
    )
}
