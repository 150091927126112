import React from "react";
import logo from "../../assets/img/logo.jpg";
import { ReactComponent as Location } from "../../assets/svg/location.svg";
import { ReactComponent as Paypal } from "../../assets/svg/paypal.svg";
import Icons from "../media-icons/Icons";
import { Link, useNavigate } from "react-router-dom";
import data from "../../data";
function Footer() {
  return (
    <>
      <div className="footer-wrapper roboto">
        <div className="footer">
          <FooterCompany />
          <FooterAboutUs />
          <FooterGetInvolve />
          <FooterQuickLinks />
          <FooterPrograms />
          <FooterBankInfo />
        </div>
        <CopyRight />
      </div>
    </>
  );
}
export default Footer;
function FooterCompany() {
  return (
    <div className="footer-company">
      <div className="footer-company-we">
        <img
          className="footer-company-logo"
          src={logo}
          alt="holistic team logo hand held plant"
        />
        <h3 className="footer-company-title">
          Kolfe Full Gospel Holistic Service
        </h3>
      </div>
      <div className="footer-company-info">
        <div className="footer-company-info-item">
          Phone
          <a className="mail-link" href="tel:+251966807291">
            <span className="footer-company-item-color">{data.phone}</span>
          </a>
        </div>
        <div className="footer-company-info-item">
          Email{" "}
          <a className="mail-link" href="mailto:kfgchurchholistic@gmail.com">
            <span className="footer-company-item-color">{data.email}</span>
          </a>
        </div>
        <div className="footer-company-info-item">
          P.O Box :{" "}
          <span className="footer-company-item-color">{data.pos}</span>
        </div>
        <div className="footer-company-info-item">
          <div>Location </div>
          <div>
            <a
              className="mail-link"
              href="https://maps.app.goo.gl/2MH6gr38kaQSJgLSA"
            >
              {" "}
              <Location className="footer-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function FooterGetInvolve() {
  const navigator = useNavigate();
  return (
    <>
      <div className="footer-get-involve-wrapper">
        <h3 className="footer-get-involve">Get Involve</h3>
        <button
          className="footer-get-involve-button"
          onClick={() => navigator("/donate")}
        >
          Donate Now
        </button>
        <div className="footer-community-wrapper">
          <h3 className="footer-join-community">Join Our Community</h3>
          <Icons
            containerStyle={{
              display: "flex",
              justifyContent: "start",
              padding: "5px 5px 3px 8px",
            }}
            style={{
              width: "25px",
              height: "25px",
              fill: "var(--footer-icon-color)",
              margin: "3px 4px",
            }}
            myClass={{
              anchor: "",
              icon: "",
              container: "",
            }}
            links={data.links}
          />
        </div>
      </div>
    </>
  );
}

function FooterAboutUs() {
  return (
    <div className="footer-aboutus-wrapper">
      <div className="footer-aboutus-title">About Us</div>

      <div className="footer-aboutus-link-wrapper">
        <Link to="/aboutus" className="footer-aboutus-link">
          Who are we
        </Link>
      </div>
      <div className="footer-aboutus-link-wrapper">
        <Link to="/aboutus/members" className="footer-aboutus-link">
          Members
        </Link>
      </div>
      <div className="footer-aboutus-link-wrapper">
        <Link to="/partener" className="footer-aboutus-link">
          Be Partener
        </Link>
      </div>
      <div className="footer-aboutus-link-wrapper">
        <Link to="/programs" className="footer-aboutus-link">
          Our Programs
        </Link>
      </div>
      <div className="footer-aboutus-link-wrapper">
        <Link to="/aboutus/contact" className="footer-aboutus-link">
          {" "}
          Contact Us
        </Link>
      </div>
    </div>
  );
}

function FooterQuickLinks() {
  return (
    <div className="footer-quick-link-wrapper">
      <div className="footer-quick-link-group">
        <div className="footer-quick-link-title">Quick Links</div>

        <div className="footer-quick-link-link-wrapper">
          <Link to="/gallery" className="footer-quick-link-link">
            Gallery
          </Link>
        </div>
        <div className="footer-quick-link-link-wrapper">
          <Link to="/blog" className="footer-quick-link-link">
            Blogs
          </Link>
        </div>
        <div className="footer-quick-link-link-wrapper">
          <Link to="/events" className="footer-quick-link-link">
            Events
          </Link>
        </div>
        <div className="footer-quick-link-link-wrapper">
          <Link to="/partener" className="footer-quick-link-link">
            Be Partener
          </Link>
        </div>
        <div className="footer-quick-link-link-wrapper">
          <Link to="/aboutus" className="footer-quick-link-link">
            About us
          </Link>
        </div>
      </div>
    </div>
  );
}

function FooterPrograms() {
  return (
    <div className="footer-program-wrapper">
      <div className="footer-program-group">
        <div className="footer-program-title">Our Programs</div>
        <div className="footer-program-link-wrapper">
          <Link to="/programs" className="footer-program-link">
            Our Programs
          </Link>
        </div>
        <div className="footer-program-link-wrapper">
          <Link
            to="/programs/family-empowerment"
            className="footer-program-link"
          >
            Family Empowerment
          </Link>
        </div>
        <div className="footer-program-link-wrapper">
          <Link to="/programs/child-care" className="footer-program-link">
            Child Care
          </Link>
        </div>
        <div className="footer-program-link-wrapper">
          <Link to="/programs/counselling" className="footer-program-link">
            Counselling
          </Link>
        </div>
        <div className="footer-program-link-wrapper">
          <Link to="/programs/support-homeless" className="footer-program-link">
            Supporting Homeless
          </Link>
        </div>
      </div>
    </div>
  );
}
function FooterBankInfo() {
  const navigator = useNavigate();
  return (
    <div className="footer-bank-wrapper">
      <div className="footer-bank-group">
        <div className="footer-bank-title">Support</div>
        <div className="footer-bank-bank-info">Bank Info</div>
        <div className="footer-one-bank">
          Holistic Commercial bank {data.cbe}
        </div>
        {/* <div className = "footer-one-bank">
        Holistic Abissinia bank {data.abyssinia}
       </div>
      <div className = "footer-one-bank">
        Holistic Awash  bank {data.awash}
       </div>
       <div className = "footer-one-bank">
        Holistic Telebirr {data.telebirr} 
       </div> */}
        <div className="footer-bank-online-support">
          <button
            className="footer-bank-online-support-button"
            onClick={() => navigator("/donate")}
          >
            <span className="support-button-text"> Online Support</span>
            <Paypal className="support-button-icon" />
          </button>
        </div>
      </div>
    </div>
  );
}

function CopyRight() {
  return (
    <div className="copyright-wrapper">
      <div className="copyright">
        <p className="copyright-text">
          <span className="copyright-symbol"> &copy;</span> Holistic service
          team all rights are reserved 2022.
        </p>
      </div>
    </div>
  );
}
