import React from "react"
import SEO from "../SEO/SEO"
import {Link} from "react-router-dom"
import useFetchClient from "../hooks/useFetchClient"
import img from "../../assets/img/pages/blog.jpg"
import Skeleton from "react-loading-skeleton";
import Error from "../404/Error"
function Blog() {
const {data,error,loading} = useFetchClient("/admin/blog");
  if(error) {
    return <Error/>
  }
  if(data) {
   data.data.sort((a,b) => -Number(new Date(a.date)) + Number(new Date(b.date)) )
  }
  return(
    <> 
    <SEO 
    title = "BLOGS"
    keywords = "Blogs,quote,kolfe mulu wengel Holistic Blogs,kfgc"
    description = "Blogs and quotes motivate peoples to be kind...."
    />
    <BlogWelcome/> 
    <div className = "blog-wrapper">
    {data &&
       <div className = "blog-flex">
        {data.data.map(blog => (
          <BlogCard blog = {blog} key = {blog.title}/>
        )) }
       </div>
    } 
    {loading &&
       <div className = "blog-skeleton-wrapper" >
         <SkeletonItem/>
          <SkeletonItem/>
          <SkeletonItem/>
       </div>
    }
    </div>
    </>
    )
}

export default Blog;

export function BlogWelcome() {
  return (
    <div className = "blog-welcome">
       <img src = {img} className = "blog-welcome-img" alt = "" loading = "lazy" />
     <div className = "blog-welcome-content-wrapper">
       <div className = "blog-welcome-content">
       <div className = "blog-welcome-title-wrapper">
          <h2 className = "blog-welcome-title oswald">
             Blog
          </h2>
          </div>
          <div className = "blog-welcome-text-wrapper">
          <p className = "blog-welcome-text">
            The best thing to do with the best things in life is to give them away.
          </p>
          </div>
       </div>
       </div>
    </div>
    )
}

function BlogCard(props) {
  return (
    <div className = "blog-card roboto">
       <img src = {process.env.PUBLIC_URL + props.blog.image} className = "blog-card-img" alt = "" loading = "lazy"/>
       <div className = "blog-card-content">
          <div className = "blog-card-title oswald">
             "{props.blog.title}"
          </div>
          
          <div className = "blog-card-description ">
           {props.blog.text.slice(0,props.blog.text.length/3)}...
          </div>
          <Link to = {"/blog/" + props.blog.id} className = "blog-card-link">
            Read More
          </Link>
       </div>
    </div>
    )
}


const SkeletonItem = () => {
  return (
    <section>
      <h2 className="section-title">
        <Skeleton duration={1} height={45} width={350} />
      </h2>
      <h2 className="section-title">
        <Skeleton duration={1} height={20} width={140} />
      </h2>
      <ul className="blog-skeleton-list">
        {Array(2)
          .fill()
          .map((item, index) => (
            <li className="blog-skeleton-card card" key={index}>
              <Skeleton height={160}  />
            </li>
          ))}
      </ul>
    </section>
  );
};


