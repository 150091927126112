import React from "react";
import SEO from "../SEO/SEO"
import img from "../../assets/img/pages/whoarewe.jpg"
import Icons from "../media-icons/Icons"
import "./Aboutus.css"
import {useNavigate} from "react-router-dom"

const membersData =[
  {
    img: '/members/alex.jpeg',
    name: 'ALEX',
    quote: 'quote of Them any thing can be.'
  },
  {
    img: '/members/bereket.jpeg',
    name: 'BEREKET',
    quote: 'quote of Them any thing can be.'
  },
  {
    img: '/members/beti.jpeg',
    name: 'BETI',
    quote: 'quote of Them any thing can be.'
  },
  {
    img: '/members/betsi.jpeg',
    name: 'BETSI',
    quote: 'quote of Them any thing can be.'
  },
  {
    img: '/members/david.jpeg',
    name: 'DAVID',
    quote: 'quote of Them any thing can be.'
  },
  {
    img: '/members/kidst.jpeg',
    name: 'KIDST',
    quote: 'quote of Them any thing can be.'
  },
  {
    img: '/members/maria.jpeg',
    name: 'MARIA',
    quote: 'quote of Them any thing can be.'
  },
  {
    img: '/members/marta.jpeg',
    name: 'MARTA',
    quote: 'quote of Them any thing can be.'
  },
  {
    img: '/members/natan.jpeg',
    name: 'NATAN',
    quote: 'quote of Them any thing can be.'
  }
];
function Members() {
  return (
    <>
    <SEO 
    title = "MEMBERS"
    keywords = "Holistic Team,KFGC Holistic Volunteers,kolfe mulu wengel Holistic programs members,Supporting kolfe mulu wengel founders,Holistic Services team founder,Holistic Services team visionaries,Holistic Members"
    description = "Kolfe mulu wengel (KFGC) Holistic team Members , volunteers  and founders ..."
    />
    <MembersWelcome/>
    <div className = "members-avatar-wrapper">
       <div className = "members-avatar-content">
          <div className = "members-avatar-title oswald">
            Team <span className = "members-avatar-title-itself">Leaders</span>
          </div>
          <div className = "members-avatar">
              {membersData.map(member => (
              <MemberCard img = {member.img} name = {member.name} quote = {member.quote} links = {{
         facebook:"https://www.facebook.com",
         twitter:"https://www.twitter.com",
         linkedin:"https://www.linkedin.com",
         telegram:"https://www.telegram.com",
         instagram:"https://www.instagram.com"
       }}  key = {member.name} />
              ))}
          </div>
       </div>
    </div>
    </>
    )
}
export default Members;


function MembersWelcome() {
const navigator  = useNavigate()
  return(
     <div className = "member-welcome">
       <img src = {img} className = "member-welcome-img" alt = "" loading = "lazy" />
     <div className = "member-welcome-content-wrapper">
       <div className = "member-welcome-content">
         <div className = "member-welcome-title-wrapper">
          <h2 className = "member-welcome-title oswald">
             Who are we
          </h2>
          </div>
          <div className = "member-welcome-text-wrapper">
          <p className = "member-welcome-text">
            Be partner with us.
          </p>
          </div>
          <div className = "member-welcome-button-wrapper">
          <button className = "member-welcome-button roboto" type = "button" onClick = {() => navigator("/partener")}>
            Join us
          </button>
          </div>
       </div>
       </div> 
    </div>
    )
}

function MemberCard(props) {
  return(
      <div className = "member-avatar-card">
            <img className = "member-avatar-img" src = {require("../../assets/img"+props.img)} alt = "" loading = "lazy"/>
            <div className = "member-avatar-body">
                   <div className = "member-name oswald">
                     {props.name}
                   </div>
                   <div className = "member-quote">
                      {props.quote}
                  </div>
                     <Icons 
                       containerStyle = {{
                           display:"flex",
                           justifyContent:"center",
                           padding:"5px 5px 3px 8px"
                            }}
                       style = {{
                          width:"25px",
                          height:"25px",
                          fill:"#ddd",
                          margin:"3px 6px",
                      }}
                      myClass = {{
                          anchor:" member-avatar-link ",
                          icon:" member-avatar-media-icon ",
                          container:" "
                      }} 
                    links = {props.links}/>
         </div>
     </div>
    )
}