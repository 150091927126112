import React from "react"
import SEO from "../../SEO/SEO"
import helpingfamily from "../../../assets/img/programs/family/helping-family.jpg"
import trackhelping from "../../../assets/img/programs/family/track-helping.jpg"
import "./program.css"
function FamillyEmpowermentDetail() {
  return (
    <>
       <SEO 
    title = "PROGRAMS - Family Empowerment"
    keywords = "Programs,KFGC Holistic programs,kolfe mulu wengel Holistic programs,Supporting kolfe mulu wengel,Holistic Services,holistic family Empowerment,supporting families"
    description = "Kolfe mulu wengel (KFGC) Holistic team Programs and services to support family in basic needs,counselling... "
    />
    <ProgramDetailContent/>
    </>
    )
}
export default FamillyEmpowermentDetail;

function ProgramDetailContent() {
  return (
   <div className = "one-program-detail-wrapper">
      <div className = "one-program-detail">
      <ProgramDetailHeader/>
      <ProgramDetailDefination/>
      <OneProgramDetailMiddleDefination/>
      </div>
  </div>
    )
}
function ProgramDetailHeader() {
  return (
     <div className = "one-program-detail-topic">
       <div className = "one-program-detail-topic-breadcrumbs">
       <span className = "one-program-breadcrumbs-title">Our Programs</span>
       <span className = "one-program-breadcrumbs-dot">●</span>
      <span className = "one-program-breadcrumbs-title">Programs</span>
      <span className = "one-program-breadcrumbs-dot">●</span>
     <span className = "one-program-breadcrumbs-title">family empowerment</span>
       </div>
       <h3 className = "one-program-title">
      Families Empowerment 
      </h3>
    </div>
    )
}
function ProgramDetailDefination() {
  return (
    <div className = "one-program-description-wrapper">
       <div className = "one-program-description roboto">
       
          <div className = "one-program-detail-paragraph">
Kolfe Mulu Wengel Holistic Service is a dedicated volunteer organization that focuses on family empowerment. Through their exceptional efforts, they provide invaluable support and guidance to families in need. The organization offers a range of services aimed at empowering families, including advice, meeting necessary needs, providing essential food supplies, counseling, guidance, and assistance with financial, psychological, and health-related challenges.
       </div>
   <div className = "one-program-detail-paragraph">
   With a holistic approach, Kolfe Mulu Wengel Holistic Service addresses the multifaceted needs of families. Their team of compassionate volunteers offers expert advice on various aspects of family life, helping individuals navigate through challenges and make informed decisions. By providing necessary needs and essential food supplies, the organization ensures that families have access to the basic resources required for their well-being.

       </div>
       </div>
       <div className = "one-program-description-img-wrapper">
       <img src = {helpingfamily} className = "one-program-description-img" alt = "" loading = "lazy"/>
       </div>
    </div>
    )
}

function OneProgramDetailMiddleDefination() {
  return (
    <div className = "one-program-middle-defination-wrapper">
   <div className = "one-program-middle-description">
   <div className = "one-program-detail-paragraph">
Counseling and guidance play a crucial role in empowering families, and Kolfe Mulu Wengel Holistic Service excels in this area. Trained professionals offer personalized counseling services to families, creating a safe and supportive environment where individuals can openly express their concerns and receive expert guidance. By addressing psychological and emotional challenges, the organization equips families with the necessary tools to overcome obstacles and build resilience. Moreover, through their financial assistance programs, Kolfe Mulu Wengel Holistic Service helps families navigate economic hardships, offering practical advice on budgeting, debt management, and savings. By promoting financial literacy and stability, the organization empowers families to take control of their financial future and break free from the cycle of poverty.
       </div>
   <div className = "one-program-detail-paragraph">
Furthermore, Kolfe Mulu Wengel Holistic Service recognizes the significance of holistic health in family empowerment. They provide access to healthcare services and promote preventive care, ensuring families can prioritize their physical and mental well-being. From organizing health awareness campaigns and workshops to facilitating medical check-ups and screenings, the organization actively works towards improving the overall health outcomes of families. By addressing health-related challenges and providing support, Kolfe Mulu Wengel Holistic Service empowers families to lead healthier lives, fostering a foundation for long-term success and well-being.
     </div>
  <div className = "one-program-detail-paragraph">
In summary, Kolfe Mulu Wengel Holistic Service serves as a steadfast pillar for families seeking empowerment and support. Through their comprehensive range of services, including counseling, financial assistance, and a focus on holistic health, the organization addresses the diverse needs of families. By providing guidance, necessary resources, and fostering a nurturing environment, Kolfe Mulu Wengel Holistic Service enables families to overcome challenges and create a brighter future. Their dedication to family empowerment leaves a lasting impact, promoting self-sufficiency, resilience, and well-being within communities.
  </div>
  </div>
  <div className = "one-program-middle-img-wrapper">
  <img src = {trackhelping}  className = "one-program-middle-img" alt = "" loading = "lazy"/>
  </div>
    </div>
    )
}