import React from "react"
import SEO from "../SEO/SEO"
import img from "../../assets/img/pages/events.jpg"
import dateFormat from "dateformat";
import useFetchClient from "../hooks/useFetchClient" 
import Error from "../404/Error"
import { ReactComponent as Calander } from "../../assets/svg/calendar.svg" 
import Icons from "../media-icons/Icons"
import Skeleton from "react-loading-skeleton";
function EventPage() {
 const {data,error,loading} = useFetchClient("/admin/event");
  if(error) {
    return <Error/>
  }
  if(data) { data.data.sort((a,b) => -Number(new Date(a.date)) + Number(new Date(b.date)) )}
  return(
    <>
    <EventWelcomePage/>
    <EventPageBody data = {data} loading = {loading}/>
    </>
    )
}

export default EventPage;

function EventWelcomePage() {
  return (
    <div className = "event-page-welcome">
       <img src = {img} className = "event-page-welcome-img" alt = "" loading = "lazy" />
     <div className = "event-page-welcome-content-wrapper">
       <div className = "event-page-welcome-content">
       <div className = "event-page-welcome-title-wrapper">
          <h2 className = "event-page-welcome-title oswald">
             Events
          </h2>
          </div>
          <div className = "event-page-welcome-text-wrapper">
          <p className = "event-page-welcome-text">
            Everyone can be great because everyone can serve.
          </p>
          </div>
       </div>
       </div>
    </div>
    )
}

function EventPageBody(props) {
  
    return(
    <div className = "event-page-wrapper">
    <SEO 
    title = "EVENTS"
    keywords = "Events,Celebration,kolfe mulu wengel Holistic Events,kfgc,KFGC Holistic Events"
    description = "Events prepare to support other peoples and facilitate fund raising..."
    />
       <div className = "event-page">
          <h3 className = "event-page-title oswald">
             ... Our <span className = "event-page-title-itself"> Events</span>
          </h3>
          { props.data &&   
          <div className = "event-page-card-wrapper roboto">
            {
             props.data.data.map(event => (
              <EventPageCard event = {event} key = {event.title}/>
             ))
           }
          </div>
          }
          { props.loading &&   
          <div className = "event-page-card-wrapper roboto">
          <SkeletonItem/>
          <SkeletonItem/>
          </div>
          }
       </div>
    </div>
    )
}

function EventPageCard(props) {
const links = {}
  for (let key in props.event.links[0]) {
    if(props.event.links[0][key]) {
      links[key] = props.event.links[0][key]
    }
  }
  return (
    <div className = "event-page-card">

       <div className = "event-page-card-top">
          <img src = {process.env.PUBLIC_URL +  props.event.image} className = "event-page-card-img" alt = "" />
          <div className = "event-page-card-topic">
             <h3 className = "event-page-card-title">
                {props.event.title}
             </h3>
             <p className = "event-page-card-quote">
           <span className = "event-page-card-quote-itself" >" </span> 
           {props.event.quote}
             </p>
             <p className = "event-page-card-date">
             <Calander className = "event-page-card-date-icon"/>
              {dateFormat(new Date(props.event.date), "ddd, mmm dd, yyyy")}
             </p>
          </div>
       </div>
                  <Icons 
                       containerStyle = {{
                           display:"flex",
                           justifyContent:"center",
                           padding:"5px 5px 3px 8px"
                            }}
                       style = {{
                          width:"22px",
                          height:"22px",
                          fill:"var(--color-primary)",
                          margin:"2px 4px",
                          cursor:"pointer"
                      }}
                      myClass = {{
                          icon:" event-page-media-icon ",
                          container:" event-page-media-icon-wrapper "
                      }} 
                    links = {links}/>
       <div className = "event-page-card-content ubuntu">
       <p className = "event-page-card-content-description">
          {props.event.text}
       </p>
       </div>
  
    </div>
    )
}

const SkeletonItem = () => {
  return (
    <section>
      <h2 className="section-title">
        <Skeleton duration={1} height={45} width={350} />
      </h2>
      <h2 className="section-title">
        <Skeleton duration={1} height={20} width={140} />
      </h2>
      <ul className="list">
        {Array(3)
          .fill()
          .map((item, index) => (
            <li className="card" key={index}>
              <Skeleton height={190} />
            </li>
          ))}
      </ul>
    </section>
  );
};